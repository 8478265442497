class BaseModel {
  DEFAULT_LOCALE = "en-US";

  _extract_locale_text(arr = [], locale = '') {
    if (arr && Array.isArray(arr)) {
      let obj = arr.find(d => d.locale === locale);

      // Fallback to english if the locale is not available
      if (!obj) {
        obj = arr.find(d => d.locale === this.DEFAULT_LOCALE);
      }
      
      if (obj) {
        return obj.text;
      }
    }

    return ''
  }

  _localeObjArrToObj(arr = []) {
    return Object.assign({}, ...arr.map(obj => ({ [obj.locale]: obj })))
  }

  _extract_locale_text_from_obj(param = {}, locale = '') {
    let obj = param[locale]

    if (!obj) {
      obj = param[this.DEFAULT_LOCALE]
    }

    if (obj) {
      return obj.text
    };

    return ''
  }
}

export default BaseModel;
