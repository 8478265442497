
import React, { useContext } from "react";

import LoadingWrapper from "wrappers/loading";

import "./index.scss";

//Context
import LocaleContext from "contexts/locale";

const Links = ({ links, loading }) => {
  const { t, locale } = useContext(LocaleContext);

  return (
    <div className="home-links">
      <div className="home__title heading-primary">{t("quickLinks")}</div>
      <LoadingWrapper loading={loading} useSpinner>
        {links.map((link, index) => {
          return (
            <a
              className="home-link text-secondary"
              key={index}
              href={link.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {link.getLocaleTitle(locale)}
            </a>
          );
        })}
      </LoadingWrapper>
    </div>
  );
};

Links.defaultProps = {
  links: []
}

export default Links;
