import { Intent } from "./group";
import { Resource } from "./resource";
import { BaseClientV2 } from "./v2client";
import * as qs from "qs";


type ResourceSearchResult = { resource: Resource, rank: number };
export type Result = ResourceSearchResult[]

class IntentV2 extends BaseClientV2 {
    BASE_URL = "intent"

    async searchDirect(filterGroupIds: string[]): Promise<Result> {
        const { data } = await this.get(`${this.BASE_URL}/direct-search`, 
            { filterGroupIds }, 
            { paramsSerializer: (params: string[]) => qs.stringify(params)}
        );

        return data?.result;
    }

    async search(intentId: string): Promise<Result> {
        const { data } = await this.get(`${this.BASE_URL}/${intentId}/search`);
        return data?.result;
    }

    async create(filterGroupIds: string[]) {
        const { data } = await this.post(`${this.BASE_URL}/`, { filterGroupIds });
        return data?.intent;
    }

    async update(id: string, filterGroupIds: string[]) {
        const { data } = await this.put(`${this.BASE_URL}/${id}/`, { filterGroupIds });
        return data?.intent;
    }

    async getIntent(id: string): Promise<Intent> {
        const { data } = await this.get(`${this.BASE_URL}/intents/${id}`);
        return data?.intent;
    }
}

export const intentV2Api = new IntentV2();