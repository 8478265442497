import Resource from './resource';

class RecommendedResource extends Resource {
  constructor(apiRecommendedResource) {
    super(apiRecommendedResource)
    this.rec = apiRecommendedResource.rec
  }


  static fromArray(arrayOfRecommendations = []) {
    return arrayOfRecommendations.map(recommendation => 
      new RecommendedResource(recommendation)
    )
  }

}

export default RecommendedResource;