import { useEffect, useState, createContext } from 'react';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from 'uuid';

import { COOKIE_AUTH_NAME, AUTH_UUID_COOKIE_NAME } from "util/constants";
import { BASE_URL } from "util/config";
import client from "api/client";
import { tokensApi } from 'api';
import jwt_decode from "jwt-decode";


const AuthContext = createContext({
  setIsAuthorized: () => {},
  setUser: () => {},
  user: {},
  org: {}
})

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ isLoading: true, acceptedTermsOfService: false})

  useEffect(() => {
    (async () => {
      let uuid = Cookies.get(AUTH_UUID_COOKIE_NAME);
      let token = Cookies.get(COOKIE_AUTH_NAME);
      try {
        if (uuid && !token) {
          // and this is where the broken request is returned

          const resp = await tokensApi.getToken(uuid)
          
          if (resp.status === 403){
            alert(`${resp.status}: 😨 Uh-oh - you can't access this subdomain of the platform with this account. Make sure you are using your school email to sign up! It might be something else though - please email us at elijah@checkcompass.ca for assistance 😅`)
          } else if (resp.status.toString()[0] === '4'){
            alert(`${resp.status}: Apologies - something went wrong with trying to log in.`)
          }

          token = resp.data.token;

          // This is where a user is logged in - their token is saved to the browser (with setupClient)
          // So this is where we register their userData and login to GA4
          window.dataLayer.push({
            event: "userData",
            user_id: jwt_decode(resp.data.token).sub,
          })

          // recording general logins
          window.dataLayer.push({
            event: "login",
            method: "normal",
            user_id: jwt_decode(resp.data.token).sub,
          })
        }

        if (token) {
          setupClient(token)
          updateState('isAuthorized')(true)
        }
      } catch(e) {}

      updateState('isLoading')(false)
      Cookies.remove(AUTH_UUID_COOKIE_NAME);
    })()
  }, [])

  useEffect(() => {
    client.defaults.addErrorInterceptor(error => {
      const isUnauthorized = error.response.status === 401;
  
      if (isUnauthorized && !window.location.href.includes('/login')) {
        logout()
      }
    })
  }, [])

  const setupClient = (token) => {
    // Set token cookie
    Cookies.set(COOKIE_AUTH_NAME, token)
    client.defaults.setToken(token)
  }

  const updateState = key => value => {
    setAuthState(state => {
      state[key] = value;

      return { ...state }
    })
  }

  const logout = () => {

    Cookies.remove(COOKIE_AUTH_NAME)
    updateState('isAuthorized')(false)
    window.location.href = '/#/login';
  }

  const authenticate = () => {
    const id = uuidv4();
    Cookies.set(AUTH_UUID_COOKIE_NAME, id);
    window.location.href = `${BASE_URL}/oauth2/auth/login/${id}?continue=${encodeURIComponent(`${window.location.origin}/login`)}`
  }

  authState.authenticate = authenticate;
  authState.setIsAuthorized = updateState('isAuthorized');
  authState.setUser = !authState.user ? updateState('user') : () => {}
  authState.setLoading = updateState('isLoading');
  authState.logout = logout;
  authState.isLoginPage = window.location.href.includes('/login');
  authState.setAcceptedTermsOfService = updateState('acceptedTermsOfService')
  authState.setHasBeenWelcomed = updateState('hasBeenWelcomed')
  authState.setOrg = updateState('org')

  return (
    <>
      <AuthContext.Provider
        value={authState}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}

export default AuthContext;