import {
  SET_LANGUAGE,
  SET_STUDENT,
  SET_FAVOURITES,
  RESET_STUDENT,
  UPDATE_STUDENT,
  UPDATE_STUDENT_START,
  FOCUS_STUDENT,
} from "../types.js";

const initialState = {
  // Set language to English unless localstorage says otherwise
  preferred_language: localStorage.getItem("lang") ? localStorage.getItem("lang") : "en",
  preferred_name: "",
  email: "",
  study_year: "0",
  university: { id: null, name: "", campus: "" },
  fav_resource: [],
  fav_clubs: [],
  fav_clubsResources: [],
  fav_mindfulness: [],
  fav_event: [],
  fav_livestream: [],
  fav_ondemand: [],
  fav_oncampus: [],
  studentSet: false,
  updating: false,
  updated: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_STUDENT:
      localStorage.setItem("lang", action.student.preferred_language);
      return {
        ...state,
        ...action.student,
        studentSet: true,
      };
    case RESET_STUDENT:
      return {
        ...initialState,
      };
    case UPDATE_STUDENT:
      return {
        ...state,
        ...action.student,
        updating: false,
        updated: true,
      };
    case UPDATE_STUDENT_START:
      return {
        ...state,
        updating: true,
      };
    case FOCUS_STUDENT:
      return {
        ...state,
        updated: false,
      };
    case SET_LANGUAGE:
      localStorage.setItem("lang", action.preferred_language);
      return {
        ...state,
        preferred_language: action.preferred_language,
      };
    case SET_FAVOURITES:
      return {
        ...state,
        ...action.favourites,
      };
    default:
      return state;
  }
}
