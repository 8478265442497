import React, { useContext } from "react";
import { Link } from "react-router-dom";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"

import AuthContext from "contexts/auth";
import LocaleContext from "contexts/locale";

import group from "imgs/my-fitness/working outtt.png";
import youtube from "imgs/my-fitness/youtube.png";
import ondemand from "imgs/my-fitness/yoga mat.png";
import gym from "imgs/my-fitness/kettlebell.png";

// import ReactGA from "react-ga";

import "./MyFitness.scss";
import { withLayout } from "hoc/withLayout";


const MyFitness = () => {
  
  const pageTitle = GA4Pageview("MyFitness")
  const { org } = useContext(AuthContext);
  const { t } = useContext(LocaleContext);

  const links = [
    {
      title: t("liveStreamClasses"),
      description: t("liveStreamHeading"),
      icon: youtube,
      link: "/fitness/live-streams",
    },
    {
      title: t("onDemandClasses"),
      description: t("onDemandHeading"),
      icon: ondemand,
      link: "/fitness/on-demand",
    },
    {
      title: t("onCampusClasses"),
      description: t("onCampusHeading"),
      icon: group,
      link: "/fitness/on-campus",
    },
  ];

  return (
    <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
      </Helmet>
      
      <div className="fitness">
        <div className="fitness__title heading-primary">{t("fitnessHeading")}</div>
        <div className="fitness__blocks">
          {links.map((link, index) => (
            <Link className="fitness-block" to={link.link} key={index}>
              <div className="fitness-block__title heading-secondary">{link.title}</div>
              <div className="fitness-block__img">
                <img src={link.icon} alt={link.title} />
              </div>
              <span className="fitness-block__desc text-primary">{link.description}</span>
            </Link>
          ))}
          <a className="fitness-block" href={org.gymTimeLink} target="_blank" rel="noopener noreferrer">
            <div className="fitness-block__title heading-secondary">{t("gymTime")}</div>
            <div className="fitness-block__img">
              <img src={gym} alt="gym icon" />
            </div>
            <span className="fitness-block__desc text-primary">{t("gymTimeHeading")}</span>
          </a>
        </div>
      </div>
      <div className="fitness-banner">
        <span className="text-banner">{t("fitnessFooter")}</span>
      </div>
    </>
  );
};

export default withLayout()(MyFitness);
