import axios from "axios";
import { BASE_URL } from "util/config";
import Cookies from 'js-cookie';
import { tokensApi } from "api";
import { COOKIE_AUTH_NAME } from "util/constants";

class ClientError extends Error {
    data: any;
  }

export class BaseClientV2 {
    baseUrl = `${BASE_URL}/api/v2`;
    authToken = undefined;
  
    client = axios.create({
        baseURL: this.baseUrl
    })
  
    async _headers () {
      return {
        'Authorization': `Bearer ${await this._authorize()}`
      }
    }
  
    async _authorize() {
      const uuid = Cookies.get(COOKIE_AUTH_NAME);
      return uuid;
    }
    
    async get(url: string, params?: any, options = {}) {
      return this.client.get(url, { params, ...options, headers: { ...await this._headers() } }).catch(e => {
        const err = new ClientError();
  
        err.message = e.response.data.message;
        err.data = e.response.data;
  
        throw err;
      })
    }
  
    async patch(url: string, body?: any, options = {}) {
      return this.client.patch(url, body, { ...options, headers: { ...await this._headers() } }).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;
  
        throw err;
      })
    }
  
    async post(url: string, body?: any, options = {}) {
      return this.client.post(url, body, { ...options, headers: await this._headers() }).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;
  
        throw err;
      })
    }
  
    async put(url: string, body?: any, options = {}) {
      return this.client.put(url, body, { ...options, headers: await this._headers() }).catch(e => {
        const err = new ClientError();
  
        err.message = e.response.data.message;
        err.data = e.response.data;
  
        throw err;
      })
    }
  
    async delete(url: string, body?: any, options = {}) {
      return this.client.delete(url, { data: body, ...options, headers: await this._headers() }).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;
  
        throw err;
      })
    }
  }