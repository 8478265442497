import { COOKIE_AUTH_NAME } from "util/constants";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";

export const getUserIDfromCookies = () => {
    try {
        return jwt_decode(Cookies.get(COOKIE_AUTH_NAME)).sub
    } catch {
        return "no user present"
    }
}