import Resource from "./resource"

export default class ResourceDate {
  constructor(apiResourceDate) {
    this.durationMinutes = apiResourceDate.durationMinutes
    this.resource = new Resource(apiResourceDate.resource)
    this.start = apiResourceDate.start
    this.end = apiResourceDate.start + (apiResourceDate.durationMinutes * 60 * 60) // Convert to milliseconds
    this.startDateOfSeries = apiResourceDate.startDateOfSeries
    this.series = apiResourceDate.series
  }

  static fromArray(arrayOfResourceDates = []) {
    return arrayOfResourceDates.map(resourceDate => new ResourceDate(resourceDate))
  }
}