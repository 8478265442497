import React, { useContext, useState } from "react";
import sprites from "imgs/sprites.svg";
import "./NoResources.scss";
import LocaleContext from "contexts/locale";

// Functionality commented thoroughly in Event.js
const Resource = ({ mode }) => {


  return (
    <div
      className={`item item--list`}
    >
      {/* <ItemDetails title={title} description={detail} link={link} optionalNotes={[address]} hits={hits} score={score}/> */}
      <div className="">

        {mode === "favs" &&
          <>
            <h1> ☁️ Oh hey! There's nothing here yet. ☁️</h1>
            Press the <span><svg className={`item__icon item__heart inline_icon`}><use href={sprites + "#icon-heart"}></use></svg></span> icon on resources in the Calendar, Survey Results, Mindfulness Library or Browse All Resources Pages to add a favourite!
          </>
        }

        {mode === "recs" &&
          <>
            <h1> 🌞 Hey! 🌞 </h1>
            Press the "Take Survey" button so that we can recommend you some really bomb mental health resources.
          </>

        }

        {mode === "normal" &&
          <>
            <h1> 🌞 Hey! 🌞 </h1>
            Sorry, we were unable to find any resources that match your exact filter/search criteria. Please try a different search or try refining your search.
          </>

        }
      </div>

    </div>
  );
};

export default React.memo(Resource);
