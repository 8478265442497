import React, { useContext } from "react";

import "./Emergency.scss";

import sanitizeHtml from 'sanitize-html';

import LocaleContext from "contexts/locale";

import defaultEmergencySupportInfo from "./default";


const Emergency = ({org}) => {

  const sanitizeConf = {
    allowedTags: ["div", "a", "br", "strong", "em"],
    allowedAttributes: { a: ["href", "class"], div: ["class"] }
  };

  const san = (i) => {
    return sanitizeHtml(i, sanitizeConf)
  }

  const { locale } = useContext(LocaleContext)
  

  let emergencySupportInfo = ''

  if (org.emergencySupportInfo && org.emergencySupportInfo.length){
    for (const x of org.emergencySupportInfo){
      if (x.locale === locale){
        emergencySupportInfo = x.text
      }
    }
  } else {
    emergencySupportInfo = defaultEmergencySupportInfo
  }

  return (
      <div className="emergency"
      dangerouslySetInnerHTML={{__html: san(emergencySupportInfo)}} />
  );
}

export default Emergency;
