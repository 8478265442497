// redux
import store from "redux/_store";

import {
  SET_ALERT,
  CLEAR_ALERT_TIMER,
  HIDE_ALERT
} from "../types";


export const sendAlert = (text, type = "") => {
  store.dispatch((dispatch, getState) => {
    let timer = getState().data.alert.timer;
    if (timer) {
      dispatch({
        type: CLEAR_ALERT_TIMER,
      });
    }
    let newTimer = setTimeout(() => {
      dispatch({
        type: HIDE_ALERT,
      });
    }, 3000);

    dispatch({
      type: SET_ALERT,
      alert: {
        text: text,
        type: type,
        timer: newTimer,
        visible: true,
      },
    });
  });
};
