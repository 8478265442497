import { get } from 'lodash';
import BaseModel from './base-model';
import Resource from './resource';

class Favourite extends BaseModel {
  constructor(apiFavourite) {

    super()

    this.id = apiFavourite.id
    this.orgId = apiFavourite.orgId
    this.resource = new Resource(apiFavourite.resource)
    this.resourceId = get(this.resource, 'id')
    this.resourceType = get(this.resource, 'type')
  }

  static fromArray(arrayOfFavourites = []) {
    return arrayOfFavourites.map(favourite => new Favourite(favourite))
  }

  isEvent() {
    return this.resourceType === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.resourceType === Resource.TYPES.QUICK_LINK;
  }

  isMentalHealth() {
    return this.resourceType === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.resourceType === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.resourceType === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.resourceType === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }

  isMindfulness() {
    return this.resourceType === Resource.TYPES.MINDFULNESS;
  }

  isFitnessLiveStream() {
    return this.resourceType === Resource.TYPES.FITNESS_LIVE_STREAM;
  }

  isFitnessOnDemand() {
    return this.resourceType === Resource.TYPES.FITNESS_ON_DEMAND;
  }
  
  isFitnessOnCampus() {
    return this.resourceType === Resource.TYPES.FITNESS_ON_CAMPUS;
  }

  isOfType(type) {
    return this.resourceType === type;
  }
}

export default Favourite;