import confetti from 'canvas-confetti';

const createConfetti = async() => {
    var myCanvas = document.querySelector('#confetti')
    myCanvas.style.display = "block"
    var myConfetti = confetti.create(myCanvas, {
        resize: true,
        useWorker: true
    });
    await myConfetti({
        particleCount: 2000,
        spread: 300,
        startVelocity: 70,
        ticks: 200, // 200 is the normal value - a higher number makes it last longer
        scalar: .6,
        shapes: ['circle'],
        colors: ['#3c8cac', '#388cdc', '#c9def1', '#95b5d5', '#b2d6f9', '#d4dce4', '#eceef0']
    });
    myCanvas.style.display = "none"
}

export default createConfetti