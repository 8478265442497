import React, { useContext, useState } from "react";

import { getDatesForWeek, getTimeRange } from "util/dataHelpers";
import CustomModal from "components/CustomModal/CustomModal";

import moment from "moment";

import Resource from "components/Items/Resource";
import Spinner from "components/Spinner/Spinner";

import LocaleContext from "contexts/locale";

const WeeklyClasses = ({ currentMonday: selectedMonday, loadingResources, onMondayChange, resources, renderTitle }) => {

  const [weekDays, setWeekDays] = useState(getDatesForWeek(moment().startOf("isoWeek")));
  const [currMonday] = useState(moment().startOf("isoWeek"));
  const [selectedClass, setSelectedClass] = useState(null);

  const { t, locale } = useContext(LocaleContext);

  const nextWeek = () => {
    let newMonday = selectedMonday.clone().add(7, "days");
    onMondayChange(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  const prevWeek = () => {
    let newMonday = selectedMonday.clone().subtract(7, "days");
    onMondayChange(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  const currWeek = () => {
    let newMonday = moment().startOf("isoWeek");
    onMondayChange(newMonday);
    setWeekDays(getDatesForWeek(newMonday));
  };

  let isCurrentWeek = currMonday.diff(selectedMonday, "days") === 0;
  let weeksAhead = selectedMonday.diff(currMonday, "weeks");
  let futureLimit = 3;

  let sortedClasses = [...resources];
  sortedClasses.sort((a, b) => moment(a.startTime, "HH:mm:ss") - moment(b.startTime, "HH:mm:ss"));

  return (
    <>
      <div className="class-page">
        {renderTitle()}
        <div className="class-page__buttons">
          {weeksAhead > 0 && (
            <button className="class-page__button--prev button--blue" onClick={prevWeek}>
              {t("prev")}
            </button>
          )}
          {!isCurrentWeek && (
            <button className="class-page__button--today button--blue" onClick={currWeek}>
              {t("today")}
            </button>
          )}
          {weeksAhead < futureLimit && (
            <button className="class-page__button--next button--blue" onClick={nextWeek}>
              {t("next")}
            </button>
          )}
        </div>
        {loadingResources ? (
          <div className="class-page__loading">
            <Spinner />
          </div>
        ) : (
          <>
            {weekDays.map((day, index) => {
              return (
                <div className={`class-page__day ${day.hasPast ? "class-page__day--past" : ""}`} key={index}>
                  <span className="class-page__date">{day.date}</span>
                  <div className="class-page__classes">
                    {sortedClasses.map((resource) => {
                      const title = resource.getLocaleTitle(locale)
                      return moment(resource.startTime).weekday() === index ? (
                        <div
                          className={`class-item ${day.hasPast ? "class-item--past" : ""}`}
                          key={resource.id}
                          onClick={() => setSelectedClass({ ...resource, date: day.dateString })}
                        >
                          <div className="class-item__title">
                            {title}
                          </div>
                          <div className="class-item__info">{getTimeRange(resource.startTime, resource.endTime)}</div>
                          {resource.imageUrl && (
                            <img className="class-item__icon" alt="fitness-icon" src={resource.imageUrl} />
                          )}
                        </div>
                      ) : null;
                    })}
                  </div>
                </div>
              );
            })}
          </>
        )}
      </div>

      {selectedClass && (
        <CustomModal handleClose={() => setSelectedClass(null)}>
          <Resource
            resource={selectedClass}
            key={selectedClass.id}
            showCalendarLink
            isPopUp
          />
        </CustomModal>
      )}
    </>
  );
};

export default WeeklyClasses;
