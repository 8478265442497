import React, { useContext, useState } from "react";
import createConfetti from '../../components/confetti.js'

import { getCalendarLink } from "util/dataHelpers";
import {ItemCalendar, ItemDetails, ItemLink, ItemSendToFriend, ItemFavourite, ItemConfirm, ItemRelevanceScore} from "./ItemComponents";
import LocaleContext from "contexts/locale";

// Functionality commented thoroughly in Event.js
const Resource = ({ onAddFavourite, onRemoveFavourite, hasImage, isFavourite, resource, isPopUp, besideFilters, showConfirmRemoveDialog, showCalendarLink, score, hits, displayTags}) => {

  let adminResourceRedirect = ''
  if (window.localStorage.getItem("staffAccountLive")){
    if (window.location.hostname === 'localhost'){
      adminResourceRedirect = `http://localhost:3002/#/dashboard/resources/resource/${resource.id}`
    } else if (window.location.hostname.includes("staging")){
      adminResourceRedirect = `https://admin.staging.compassnext.ca/#/dashboard/resources/resource/${resource.id}`
    } else if (window.location.hostname.includes("checkcompass")){
      adminResourceRedirect = `https://admin.checkcompass.ca/#/dashboard/resources/resource/${resource.id}`
    } else {
      adminResourceRedirect = `http://localhost:3002/#/dashboard/resources/resource/${resource.id}`
    }
  }
  
  const { t, locale } = useContext(LocaleContext);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const title = resource.getLocaleTitle(locale)
  const detail = resource.getLocaleDetail(locale);
  const address = resource.getLocaleLocation(locale);
  const link = resource.link;
  const image = resource.imageUrl;

  const handleFavourite = async () => {
    if (onAddFavourite && onRemoveFavourite) {
      if (!isFavourite) {
        resource.favsCount = resource.favsCount + 1
        await onAddFavourite(resource)
        createConfetti()
      } else if(showConfirmRemoveDialog) {
        setConfirmOpen(true);
      } else {
        resource.favsCount = resource.favsCount - 1
        await onRemoveFavourite(resource);
      }
    }
  };

  const handleConfirmRemove = async () => {
    await onRemoveFavourite(resource);
    setConfirmOpen(false);
  };

  return (
    <div
      className={`item ${isPopUp ? "item--popup" : "item--list"} ${besideFilters ? "item--filters" : ""} ${
        hasImage ? "item--image" : ""
      }`}
    >
      {image && <img className="item__img" src={image} alt={title} />}
      <ItemDetails title={title} description={detail} link={link} optionalNotes={[address]} hits={hits} displayTags={displayTags} score={score}/>
      <div className="item__options">
        {/* <ItemLink link={link} label={t("learnMore")} title={title} score={score}/> */}
        {/* {showCalendarLink && <ItemCalendar calendarLink={calendarLink} />} */}
        <ItemSendToFriend content={resource} title={t("resource")} />
        <ItemFavourite isFavourite={isFavourite} handleFavourite={handleFavourite} favsCount={resource.favsCount}/>
        {score && <ItemRelevanceScore score={score} />}
        {/* {score && <p> {t("relevanceScore")}: {score} </p>} */}
        {adminResourceRedirect && <a href={adminResourceRedirect} rel="noreferrer" target="_blank"> See on admin </a>}
      </div>

      {confirmOpen && <ItemConfirm setConfirmOpen={setConfirmOpen} handleConfirm={handleConfirmRemove} />}
    </div>
  );
};

export default React.memo(Resource);
