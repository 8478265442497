import React, {useContext, useState } from "react";
import moment from "moment";

//Components
import sprites from "imgs/sprites.svg";
import LocaleContext from "contexts/locale";

import LoadingWrapper from "wrappers/loading";
import { getTimeRange } from "util/dataHelpers";

import "./index.scss";


/**
 * This is the number of events the events view will shift by
 */
const EVENTS_OFFSET = 3;

const Events = ({ events, handleSelectedEvent, loading }) => {
  const { t, locale } = useContext(LocaleContext);

  const [eventTodayNum, setEventTodayNum] = useState(0);

  let todaysDate = moment().format("YYYY-MM-DD");

  const nextTodaysEvents = () => {
    setEventTodayNum(eventTodayNum + EVENTS_OFFSET);
  };

  const prevTodaysEvents = () => {
    setEventTodayNum(eventTodayNum - EVENTS_OFFSET);
  };

  const hasPreviousEvents = eventTodayNum > 0;
  const hasNextEvents = eventTodayNum + EVENTS_OFFSET < events.length;

  return (
    <div className="home-events">
      <svg
        className={`home-events__icon--prev ${!hasPreviousEvents ? "home-events__icon--hidden" : ""}`}
        onClick={prevTodaysEvents}
      >
        <use href={sprites + "#icon-arrow"}></use>
      </svg>
      <div className="home-events__title heading-primary">{t("happeningToday")}</div>
      <svg
        className={`home-events__icon--next ${!hasNextEvents ? "home-events__icon--hidden" : ""}`}
        onClick={nextTodaysEvents}
      >
        <use href={sprites + "#icon-arrow"}></use>
      </svg>
      <LoadingWrapper loading={loading} useSpinner>
        <div className="home-events__list">
          {events.length > 0 ? (
            events.slice(eventTodayNum, eventTodayNum + 3).map((event, index) => {
              
              const resource = event.resource

              return (
                <div
                  className="home-event"
                  key={index}
                  onClick={() => {
                    if (handleSelectedEvent) {
                      handleSelectedEvent({ ...resource, date: todaysDate })
                    }
                  }}
                >
                  <div className="home-event__title text-secondary--bold">
                    {resource.getLocaleTitle(locale)}
                  </div>
                  <div className="home-event__details">
                    <div className="home-event__time text-tertiary">
                      {getTimeRange(event.start, event.start + event.durationMinutes * 60000)}
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="home-events__none-title heading-tertiary">{t("noEventsToday")}</div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default Events;
