import React, { useState} from "react";

import { motion } from "framer-motion"
import Backdrop from "../Backdrop"
import "./index.scss";
import { notification } from "antd";

const Modal = ({ handleClose, setNotificationsEnabled }) => {

    const [text, setText ] = useState("Is it okay if we use browser notifications to check in with you from time to time?")

    const exitDuration = 1

    const handleOk = () => {
        console.log("Okay")
        handleClose()
        Notification.requestPermission().then(perm => {
            if(perm){
                setNotificationsEnabled(true)
                // console.log("permission granted")
            }
        })

    }

    const dropIn = {
        hidden: {
            y: "-100vh",
            opacity: 0,
        },
        visible: {
            y: "0",
            opacity: 1,
            transition: {
                duration: 0.1,
                type: "spring",
                damping: 25,
                stiffness: 150,
            },
        },
        exit: {
            y: "100vh",
            opacity: 0,
            transition: {
                duration: exitDuration,
                type: "intertia",
                velocity: 300
            },
        }
    }

    return (
        <Backdrop onClick={handleClose} exitDuration={1}>
            <motion.div
                onClick={(e) => e.stopPropagation()}
                className="modal orange-gradient"
                variants={dropIn}
                drag
                initial="hidden"
                animate="visible"
                exit="exit"
            >

                <p className = "text">{text}</p>
                <div className = "options">
                    <button onClick={handleOk}>Enable browser notifications</button>
                    <button onClick={() => {
                        handleClose();
                        localStorage.setItem("notificationPermission", "denied")}} >No thanks</button>
                </div>

            </motion.div>


        </Backdrop>
    )
}

export default Modal