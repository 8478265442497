import LoadingPage from "pages/LoginSystem/LoadingPage";
import Spinner from "components/Spinner/Spinner";

const LoadingWrapper = (props) => {
  if (props.loading) {
    if (props.useSpinner) {
      return (
        <div className="list-page__loading" id="loading">
          <Spinner />
        </div>
      )
    }
    return (
      <LoadingPage />
    )
  }

  return props.children;
};

export default LoadingWrapper