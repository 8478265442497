import { Card, CardBody, Center, HStack, Modal, ModalContent, ModalOverlay, Stack, Text, VStack, useDisclosure } from "@chakra-ui/react";
import { FilterGroupPicker } from "components/RecommendationsV2/FilterGroupPicker"
import { EmptyResourceCard, ResourceCard } from "components/RecommendationsV2/Resource";
import { withV2Layout } from "hoc/withLayout"
import { useIntent } from "hooks/useIntent";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

type Params = {
    id: string
}

const RecommendationsForIntent= () => {
    const { id } = useParams<Params>();

    const intent = useIntent(id);
    const { result } = intent;

    const resources = useMemo(() => {
        return result?.sort((a, b) => b.rank - a.rank).map(result => result.resource);
    }, [result])

    return (
        <HStack w='full' h='full'>
            <VStack w='40%' alignItems='end'>
                <Card
                    w='70%'
                    background='none'
                    border='none'
                    boxShadow='none'
                >
                    <CardBody>
                        <Stack direction='column' p={6} sx={{ backgroundColor: 'rgba(255,255,255,0.3)' }}>
                            <Text fontWeight={900} fontSize={24}>Filters</Text>
                            <FilterGroupPicker useIntent={intent} />
                        </Stack>
                    </CardBody>
                </Card>
            </VStack>
            <VStack w='60%' top={0} h='full'>
                {!!resources.length ? resources.map((resource) => (<ResourceCard resource={resource} />)) : <EmptyResourceCard />}
            </VStack>
        </HStack>
    )
}

export default withV2Layout()(RecommendationsForIntent)