import { Resource } from "./resource";
import { BaseClientV2 } from "./v2client";

export type Intent = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    compassUserId: string;
    filterGroups: FilterGroup[];
}

export type FilterGroup = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    group: Group;
    intents?: Intent[];
}
  
  
export type Group = {
    id: string;
    createdAt: Date;
    updatedAt: Date;
    name: string;
    children?: Group[];
    isExclusive?: boolean;
    isFilterable?: boolean;
    parent?: Group;
    resources?: Resource[];
    filterGroups?: FilterGroup[];
}

class GroupV2 extends BaseClientV2 {
    BASE_URL = "/group"

    async getGroups(search?: string): Promise<Group[]> {
        const { data } = await super.get(`${this.BASE_URL}/`);
        return data?.groups;
    }

    async getGroup(id: string): Promise<Group> {
        const { data } = await super.get(`${this.BASE_URL}/${id}`);
        return data?.group;
    }

    async getResources(id: string): Promise<Resource[]> {
        const { data } = await super.get(`${this.BASE_URL}/${id}/resources`);
        return data?.resources;
    }

    async create(group: Partial<Group>): Promise<Group> {
        const { data } = await super.post(`${this.BASE_URL}/`, { group });
        return data?.group;
    }

    async update(id: string, group: Partial<Group>): Promise<Group> {
        const { data } = await super.put(`${this.BASE_URL}/${id}`, { group });
        return data?.group;
    }

    async addToGroup(groupId: string, resourceId: string): Promise<Group> {
        const { data } = await super.post(`${this.BASE_URL}/${groupId}/resources/${resourceId}`);
        return data?.group;
    }

    async removeFromGroup(groupId: string, resourceId: string): Promise<Group> {
        const { data } = await super.delete(`${this.BASE_URL}/${groupId}/resources/${resourceId}`);
        return data?.group;
    }

    async setParent(groupId: string, parentId: string): Promise<Group> {
        const { data } = await super.post(`${this.BASE_URL}/${groupId}/parent/${parentId}`);
        return data?.group;
    }

    async removeParent(groupId: string): Promise<Group> {
        const { data } = await super.delete(`${this.BASE_URL}/${groupId}/parent`);
        return data?.group;
    }

    async addChild(groupId: string, childId: string): Promise<Group> {
        const { data } = await super.post(`${this.BASE_URL}/${groupId}/child/${childId}`);
        return data?.group;
    }

    async removeChild(groupId: string, childId: string): Promise<Group> {
        const { data } = await super.delete(`${this.BASE_URL}/${groupId}/child/${childId}`);
        return data?.group;
    }

    async createFilterGroup(groupId: string) {
        await super.post(`${this.BASE_URL}/${groupId}/filter-group`);
    }

    async getParents(): Promise<Group[]> {
        const { data } = await super.get(`${this.BASE_URL}/parents`);
        return data?.groups;
    }
}

export const groupV2Api = new GroupV2();