import { Card, CardBody, Stack, Text } from "@chakra-ui/react"
import { withV2Layout } from "hoc/withLayout"
import { Link } from "react-router-dom"

const RecommendationsIntro = () => {
    const OPTIONS = [
        { title: "Immediately", subtitle: "It’s an emergency", href: "/recommend/emergency"},
        { title: "This Week", subtitle: "I need something pretty soon", href: "/recommend/new"},
        { title: "Unsure", subtitle: "I’m not really in a rush", href: "/recommend/new"},
    ]

    return (
        <Stack alignItems='center'>
            <Card
                w='40%'
                background='none'
                border='none'
                boxShadow='none'
            >
                <CardBody>
                    <Stack 
                        direction='column' 
                        p={12} 
                        spacing={6}
                        alignItems='center'
                        sx={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
                        >
                            <Stack py={24} alignItems='center'>
                                <Text color='black' fontSize={32} fontWeight={900} mb={0}>How soon are you looking for support?</Text>
                                <Text color='black' fontSize={20} fontWeight={400}>We want to make sure you have access to the best possible resources you need!</Text>
                            </Stack>
                            <Stack w='full'>
                                {OPTIONS.map(({ title, subtitle, href }) => (
                                    <Link to={href}>
                                        <Card w='full' borderRadius={12}>
                                            <CardBody>
                                                <Stack>
                                                    <Text fontSize={18} fontWeight={800}>{title}</Text>
                                                    <Text fontSize={16} fontWeight={500}>{subtitle}</Text>
                                                </Stack>
                                            </CardBody>
                                        </Card>
                                    </Link>
                                ))}
                            </Stack>
                    </Stack>
                </CardBody>
            </Card>
        </Stack>
    )
}

export default withV2Layout()(RecommendationsIntro)