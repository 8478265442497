import React, { useContext } from "react";
import { Helmet } from "react-helmet"
import { useHistory } from "react-router-dom";
import pageview from "../../util/pageview"
import { studentApi } from "api";
import styles from "./index.scss";

import wwLogo from "imgs/navbar/ww-logo.png";
import WW from "imgs/navbar/master-logo.png";

import LanguageToggle from "components/LanguageToggle/LanguageToggle";
import AuthContext from "contexts/auth";
import LocaleContext from "contexts/locale";

const Login = () => {
    const pageTitle = "Login"
	const { t } = useContext(LocaleContext);
	const { authenticate, isAuthorized, setUser} = useContext(AuthContext)
	const history = useHistory();

    const checkIfAuthorized = async() => {
        const data = await studentApi.getProfile()

        const studentData = data.studentData
        setUser(studentData)

        // Decide where to send user
        if (studentData && studentData.state && studentData.state.homepage && typeof studentData.state.homepage === 'string'){
            history.push(`/${studentData.state.homepage}`);
        }
    }

    if (isAuthorized){
        history.push("/mental-health")
        checkIfAuthorized()
    }

	const handleClick = () => {
		authenticate()
	};

	return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{pageTitle}</title>
            </Helmet>

                <div className="onboarding">
                    {/* <LanguageToggle /> */}

                    <div className="onboarding__logo-container">
                        <img src={wwLogo} alt={"logo"} />
                        <img src={WW} alt={"logo"} />
                        <h1 className="onboarding__onboarding-copy">
                            {t("yourHolisticPlatform")}
                        </h1>
                    </div>

                    <div className="onboarding__content">
                        <span className="onboarding__title">{t("welcomeBack")}</span>
                        <input
                            onChange={()=> {}} // including this prevents a warning - because there is a value on the input, it wants an onChange handler
                            className="circle-button"
                            value={t("enter")}
                            onClick={handleClick}
                        />
                    </div>
                </div>
        </>
	);
};

export default Login;