import { Pagination } from 'antd';

const CompassPagination = props => {
  function itemRender(current, type, originalElement) {
    if (type === 'prev') {
      return <a>Previous</a>;
    }
    if (type === 'next') {
      return <a>Next</a>;
    }
    return originalElement;
  }

  return (
    <Pagination itemRender={itemRender} {...props}/>
  )
}

export default CompassPagination;
