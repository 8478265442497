import React from "react";

import "./index.scss";
import { BASE_URL } from "util/config";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";


const SurveyRedirectButton = ({text, locale, formID }) => {

  const staffAccount = window.localStorage.getItem("staffAccountRecord")
  const staffAccountParam = staffAccount === 'true' ? 'true' : 'false'
    
  const clickTracker = (e) => {
    window.dataLayer.push({
        event: 'navflow_interaction',
        user_id: getUserIDfromCookies(),
        eventProps: {
            category: "intakeq",
            action: "click",
            label: `navflow intakeQ redirect to: ${e.target.href}`,
            value: "7005", // navflow can be 7000
            transport: 'beacon' // this is supposedly for GA4 when doing an outbound redirect
            //nonInteraction: true // this is for events where the user didn't interact I guess...?
        }
      });
  }
  // Navflow survey redirect URL
  let surveyURL = `${BASE_URL}/survey/start?locale=${locale}&staffAccount=${staffAccountParam}`

  // For now, Navflow suggestions aren't up to par, so static survey still being used instead
  if (formID){
      surveyURL = `https://intakeq.com/new/${formID}`
  }

  return (
    <a href = { surveyURL } onClick = {clickTracker}>
        <button className = "tab__button">
            {text}
        </button>
    </a>
  )

}

export default SurveyRedirectButton;
