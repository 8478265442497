import React, { useContext, useState, useEffect } from "react";
import { Collapse, Space, Button } from "antd";

import "./filters.scss";


import LocaleContext from "contexts/locale";

const { Panel } = Collapse;


const FilterList = ({ isApplied, list = [], type = "", handleFilter, title }) => {
  const { locale } = useContext(LocaleContext);


  // Remove display of the allTag
  const allTag = '0879982d-2544-45ef-a2f9-fddd8175d8c0'
  const newList = []
  for (const filter of list){
    if (filter.id !== allTag){
      newList.push(filter)
    }
  }
  list = [...newList]

  // featureflag
  let expandedFilters = "1"
  if (window.localStorage.getItem("featureflag_expandedfilters") === "false") expandedFilters = "0"

  return (
    <>
      <div className="filters__group">
        <Collapse className="filters__collapse" defaultActiveKey={["1"]}>
          <Panel className="filters__panel" header={title} key={expandedFilters}>
            <Space direction="vertical">
              {list.map((filter, index) => {
                return (
                  <div className="filter" key={index} onClick={() => handleFilter(filter.id, type)}>
                    <svg
                      className={`filter__svg ${
                        isApplied(filter, type) ? "filter__svg--filled" : ""
                      }`}
                    >
                      <circle className="filter__svg-border" cx="11" cy="11" r="9.6" fill="none" />
                      <circle className="filter__svg-fill" cx="11" cy="11" r="9.3" stroke="white" fill="white" />
                    </svg>
                    <div className="filter__label">{filter.getLocaleTitle(locale)}</div>
                  </div>
                );
              })}
            </Space>
          </Panel>
        </Collapse>
      </div>
    </>
  )
}

const Filters = ({ appliedFilters, updateAppliedFilters = () => {}, besideCalendar, tags, handleFilter, inDrawer }) => {
  const { locale, t} = useContext(LocaleContext);

  const [ disableDeselect, setDisableDeselect ] = useState(true)

  let groupedResourceTags = {}

  if (tags.getGroupedLocaleTags) {
    groupedResourceTags = tags.getGroupedLocaleTags(locale)
  }

  const groupKeys = Object.keys(groupedResourceTags);

  const handleDeselect = () => {
    updateAppliedFilters({tags: []})
    setDisableDeselect(false)
  }

  useEffect(() => {

    setDisableDeselect(true)
    if (appliedFilters.tags?.length){
      setDisableDeselect(false)
    }

  }, [appliedFilters])
  

  const isApplied = (filter, type) => appliedFilters[type] && appliedFilters[type].includes(filter.id)

  return (
    <div className={`filters ${inDrawer ? "filters--drawer" : ""} ${besideCalendar ? "filters--calendar" : ""}`}>
      <Space direction="vertical">
        <Button
        disabled = {disableDeselect}
        className = {`deselect-all-${disableDeselect} deselect-all`}
        type="primary"
        onClick={handleDeselect}>
          {t("deselectAll")}
        </Button>
        {groupKeys.map((key, index) => {

          return (
            <FilterList
              key={index}
              isApplied={isApplied}
              updateAppliedFilters={updateAppliedFilters}
              list={groupedResourceTags[key]}
              type={'tags'}
              handleFilter={handleFilter}
              title={key}
            />
          )
        })}
      </Space>
    </div>
  );
};

Filters.defaultProps = {
  tags: []
}

export default Filters;
