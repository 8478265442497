import React from "react";
import TagManager from 'react-gtm-module'
import ReactDOM from "react-dom";
import { HashRouter as Router } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./index.scss";
import "./styles/main.scss";

import App from "./App"; //
import * as serviceWorker from "util/serviceWorker";

// Redux setup
import { Provider } from "react-redux";
import { SENTRY_DSN, SENTRY_SAMPLE_RATE } from "constants";

import store from "./redux/_store";

// Contexts
import { AuthContextProvider } from "contexts/auth";
import { getUserIDfromCookies } from "util/getUserIdFromCookie";
import { ChakraProvider } from "@chakra-ui/react";

const getEnvironment = () => {
    const url = window.location.href;

    if (url.includes("localhost")
    || url.includes(".compassdev.ca")) return "local"

    if (url.includes(".compassnext.ca")) return "staging"
    if (url.includes(".checkcompass.ca")) return "prod"
}

const staffAccountRecord = window.localStorage.getItem('staffAccountRecord') === 'true' ? true : false
TagManager.initialize({
    gtmId: 'GTM-WVNFV7R',
    dataLayer: {
        environment: getEnvironment(), // this might just be useful for GA4 debugging for staff accounts
        debugger: window.localStorage.debuggerName,
        user_id: getUserIDfromCookies(),
        user_org: (window.location.hostname).split(".")[0],
        userProject: 'student-frontend',
        staffAccount: staffAccountRecord, // include this to send to Mango Dev GA4 Property, even on Prod
    }
})

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  staffAccount: staffAccountRecord
});

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <AuthContextProvider>
        <ChakraProvider>
          <App />
        </ChakraProvider>
      </AuthContextProvider>
    </Router>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
