import { Box } from "@chakra-ui/react"
import Footer from "components/Footer/Footer"
import NavBar from "components/NavBar/NavBar"
import AuthContext from "contexts/auth"
import { ComponentType, useContext } from "react"

export const withLayout = () => (Component: ComponentType) => (props: any) => {
    const { user, org } = useContext(AuthContext)

    return (
      <>
        <NavBar user={user} org={org} />
        <Component {...props} />
        <Footer />
      </>
    )
}

export const withV2Layout = () => (Component: ComponentType<any>) => (props: any) => {
    return (
        <Box bg='url("/BG.svg")' bgSize='cover' bgRepeat='no-repeat' height='100vh' alignItems='center'>
            <Component {...props} />
        </Box>
    )
}