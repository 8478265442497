
import React, { useCallback, useEffect, useState } from "react";
import GA4Pageview from "../../util/pageview";
import { Helmet } from "react-helmet"
import moment from "moment";
import { get } from "lodash";
import { AnimatePresence } from "framer-motion";
import Modal from "../../components/EngagementModalComponents/Modal/index"

import { favouritesApi, resourcesApi } from "api";

//Components
import Calendar from "components/Calendar/Calendar";
import CustomModal from "components/CustomModal/CustomModal";
import Resource from "components/Items/Resource";

import Events from './events';
import Links from './links';

import { getUserIDfromCookies } from "util/getUserIdFromCookie";

import "./index.scss";
import { withLayout } from "hoc/withLayout";

const Home = () => {

  const pageTitle = GA4Pageview("Home")
  
  // Todays Events
  const [todaysEvents, setTodaysEvents] = useState([]);
  const [loadingEvents, setLoadingEvents] = useState(false);

  // Quick Links
  const [quickLinks, setQuickLinks] = useState([]);

  const [resourceDates, setResourceDates] = useState([]); // consider renaming to "calendarResources", which are within the calendar start and end dates
  const [eventTags, setEventTags] = useState([]);
  const [selectedResourceDate, setSelectedResourceDate] = useState(null);
  const [loadingQuickLinks, setLoadingQuickLinks] = useState(false);
  const [appliedFilters, setAppliedFilters] = useState({});
  const [calendarStartDate, setCalendarStartDate] = useState(null);
  const [calendarEndDate, setCalendarEndDate] = useState(null);
  const [selectedResourceFavourite, setSelectedResourceFavourite] = useState(null)

  useEffect(() => {
    (async () => {
      setLoadingEvents(true)

      try {
        const start = moment().startOf('day').valueOf()
        const end = moment().endOf('day').valueOf()

        const result = await resourcesApi.getEventsWithinRange(start, end)
        setTodaysEvents(result.data)
      } catch(e) {}

      setLoadingEvents(false)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      if (calendarStartDate && calendarEndDate) {
        try {
          const result = await resourcesApi.getCalendarResources(
            moment(calendarStartDate).valueOf(),
            moment(calendarEndDate).valueOf(),
            appliedFilters
          )
          setResourceDates(result.data)
        } catch(e) {}
      }
    })()
  }, [appliedFilters, calendarEndDate, calendarStartDate])

  useEffect(() => {
    (async () => {
      try {
        const result = await resourcesApi.getCalendarResourceTags({}, { fetchAll: true })
        setEventTags(result.data)
      } catch(e) {}
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setLoadingQuickLinks(true)

      try {
        const result = await resourcesApi.getQuickLinkResources({}, { fetchAll: true })
        setQuickLinks(result.data)
      } catch(e) {}

      setLoadingQuickLinks(false)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      await fetchSelectedResourceFavourite(selectedResourceDate)
    })()
  }, [selectedResourceDate])


  const fetchSelectedResourceFavourite = async resource => {
    if (resource) {
        console.log("attempted to fetch this resource", resource)
      try {
        const resourceId = resource.resource.id // The favourites API returns an object that has the whole resource object as a field
        console.log(resourceId)
        const fav = await favouritesApi.getFavourite(resourceId)
        setSelectedResourceFavourite(fav.data)
      } catch(e) {
        setSelectedResourceFavourite(null)
      }
    }
  }

  const onAddFavouriteResource = useCallback(async resource => {
    await favouritesApi.addFavourite(resource.id)
    await fetchSelectedResourceFavourite(resource)
    setSelectedResourceFavourite(true)


    window.dataLayer.push({
        event: "resource_favourited",
        user_id: getUserIDfromCookies(),
        eventProps: {
            category: "resource_interest",
            action: "click",
            eventPage: "Home",
            label: `${resource.title[0]?.text}`,
            transport: "beacon"
        }
    })

    window.dataLayer.push({
        event: "select_item",
        user_id: getUserIDfromCookies(),
        item_list_name: "resource_favorited",
        items: [
            {
                item_name: resource.title[0]?.text,
                item_category: window.location.pathname,
            }
        ]
    })
    
  }, [])

  const onRemoveFavouriteResource = useCallback(async resource => {
    if (selectedResourceFavourite) {
      await favouritesApi.removeFavourite(selectedResourceFavourite.id)
      await fetchSelectedResourceFavourite(resource)
    }
  }, [selectedResourceFavourite])

  const onCalendarEvent = event => {
    setSelectedResourceDate(
      resourceDates.find(_event => event.event.id === _event.resource.id)
    )
  }

  const onEventSelect = resource => {
    setSelectedResourceDate({ resource });
  }

  const handleClose = () => {
    setSelectedResourceDate(null);
  };

  const handleFilter = useCallback((newId, key) => {
    let newAppliedFilters = { ...appliedFilters };
    let filterArr = get(newAppliedFilters, key, [])
    let filterSet = new Set(filterArr)

    if (filterSet.has(newId)) {
      filterSet.delete(newId)
    } else {
      filterSet.add(newId)
    }
    
    newAppliedFilters[key] = Array.from(filterSet)

    setAppliedFilters(newAppliedFilters);
  }, [appliedFilters]);

  const onDateChange = useCallback((startDate, endDate) => {
    setCalendarStartDate(startDate)
    setCalendarEndDate(endDate)
  }, [])

  const hasSelectedEvent = !!selectedResourceDate;

  return (
    <>

      <Helmet>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
      </Helmet>

      <div className="home">
        <Events
          events={todaysEvents}
          handleSelectedEvent={onEventSelect}
          loading={loadingEvents}
        />

        <Links links={quickLinks} loading={loadingQuickLinks} />

        <div className="home-calendar">
          <Calendar
            events={resourceDates}
            tags={eventTags}
            handleFilter={handleFilter}
            appliedFilters={appliedFilters}
            updateAppliedFilters={setAppliedFilters}
            onDateChange={onDateChange}
            onCalendarEvent={onCalendarEvent}
          />
        </div>

        <CustomModal open={hasSelectedEvent} handleClose={handleClose}>
          <Resource
            resource={get(selectedResourceDate, 'resource')}
            isPopUp
            onAddFavourite={onAddFavouriteResource}
            onRemoveFavourite={onRemoveFavouriteResource}
            isFavourite={!!selectedResourceFavourite}
            showCalendarLink
          />
        </CustomModal>
      </div>
    </>
  );
};

export default withLayout()(Home);
