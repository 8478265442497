import { Link, Text, VStack } from "@chakra-ui/react";
import LocaleContext from "contexts/locale";
import { withLayout } from "hoc/withLayout";
import { useContext } from "react";
import ReactHtmlParser from "react-html-parser";
import * as DOMPurify from "dompurify";

const WaitForReccomendations = () => {
    const { t } = useContext(LocaleContext);

    return (
        <VStack alignSelf='center'>
            <Text fontSize='large' align='left' maxW="80%">
                {ReactHtmlParser(DOMPurify.sanitize(t("waitForRecommendations")))}
            </Text>
        </VStack>
    )
}

export default withLayout()(WaitForReccomendations);