import React, { useContext, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import moment from "moment-timezone";

import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import Filters from "components/Filters";

import LocaleContext from "contexts/locale";

import "./Calendar.scss";
import { first } from "lodash";


const CALENDAR_VIEWS = {
  dayGridMonth: 'dayGridMonth',
  timeGridWeek: 'timeGridWeek',
  timeGridDay: 'timeGridDay'
}
// FullCalendar Docs: https://fullcalendar.io/docs#toc
const Calendar = ({ events: eventResources, onCalendarEvent, tags, appliedFilters, updateAppliedFilters, handleFilter, onDateChange }) => {

  const initialCalendarView = localStorage.getItem("initial-view")

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [currentDates, setCurrentDates] = useState([])

  const { t, locale } = useContext(LocaleContext);

  // find the offset of user time from UTC in the current moment
  const userOffset = moment().utcOffset()/60

  const events = eventResources.map(event => {

    // check if the first occurence has a different offset
    const firstEventISO= moment(event.startDateOfSeries).toISOString(true)
    const offsetDifferenceOfFirstEvent = moment(firstEventISO).utcOffset()/60 - userOffset

    // calculate the difference between the user offset in the current moment and the offset at the time of the event
    // Because of offset changes within timezones, like for DST, this difference might exist
    // We correct for this difference so that a 9:30 event in october recurs to still be displayed as a 9:30 event in mid November
    const ISO8601_UTC = moment(event.start).toISOString(true) // adding the true variable will return a local ISO string rather than a UTC one
    const eventOffsetDifference = moment(ISO8601_UTC).utcOffset()/60 - userOffset - offsetDifferenceOfFirstEvent
    const millis_in_an_hour = 3600000

    return {
      start: event.start - (eventOffsetDifference * millis_in_an_hour),
    //   start: event.start,
      end: event.start + event.durationMinutes * 60000,
      id: event.resource.id,
      title: event.resource.getLocaleTitle(locale),
    }
  })

  const lang = [];

  return (
    <div className="calendar">
      <div className="calendar__title heading-primary">
        {t("myCampusCalendar")}
      </div>

      <div className="calendar__filters">
        <Filters
        tags={tags}
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        handleFilter={handleFilter} />
      </div>

      <button className="calendar__filter-button button--blue" onClick={() => setDrawerOpen(true)}>
        Filters
      </button>

      <CustomDrawer open={drawerOpen} handleClose={() => setDrawerOpen(false)}>
        <Filters
        tags={tags}
        appliedFilters={appliedFilters}
        updateAppliedFilters={updateAppliedFilters}
        handleFilter={handleFilter}
        besideCalendar />
      </CustomDrawer>

      <div className="calendar__display">
        <FullCalendar
          datesSet={function(view) {
            let calendarView = view.view.type
            localStorage.setItem('initial-view', calendarView);

            if (onDateChange) {
              const startSeconds = moment(view.start).valueOf()
              const endSeconds = moment(view.end).valueOf()
              const prevStartSeconds = moment(currentDates[0]).valueOf()
              const prevEndSeconds = moment(currentDates[1]).valueOf()

              // This is to ensure that the date actually changes before the callback is called.
              if (startSeconds !== prevStartSeconds || endSeconds !== prevEndSeconds) {
                onDateChange(view.start, view.end)
                setCurrentDates([view.start, view.end])
              }
            }
          }}
          dragScroll={true}
          locale={lang}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: `${CALENDAR_VIEWS.dayGridMonth},${CALENDAR_VIEWS.timeGridWeek},${CALENDAR_VIEWS.timeGridDay}`,
          }}
          buttonIcons={false}
          buttonText={{
            today: t("today"),
            week: t("week"),
            month: t("month"),
            day: t("day"),
            prev: t("prev"),
            next: t("next"),
          }}
          initialView={initialCalendarView || CALENDAR_VIEWS.dayGridMonth}
          eventClick={onCalendarEvent}
          events={events} //displays events onto the calendar
          dayMaxEvents={true}
          themeSystem="standard"
          fixedWeekCount={false}
          showNonCurrentDates={false}
          eventTimeFormat={{          
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: true,
            meridiem: 'short',
          }}
        />
      </div>
    </div>
  );
};

export default React.memo(Calendar);
