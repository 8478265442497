const locales = {
  "en-US": "Anglais",
  "fr-CA": "Français",
  
  hi: 'Salut',
  // Nav Bar
  mentalHealth: "Santé Mentale",
  myCommunity: "Ma communauté",
  myMentalHealth: "Ma santé mentale",
  myPhysicalHealth: "Mon bien-être physique",
  myFavourites: "Mes Favoris",
  emergencySupport: "Soutien d'urgence",
  profile: "Profil",
  favourites: "Favoris",
  logout: "Déconnexion",
  // My Community Page
  happeningToday: "Ce qui se passe aujourd'hui",
  quickLinks: "Lien rapides",
  noEventsToday: "Pas d'événements aujourd'hui",
  myCampusCalendar: "Le calendrier de mon campus",
  // My Mental Health Page
  myRecommendedResources: "Mes ressources recommandées",
  recommendedHeading:
    "Tu ne sais pas par où commencer ? Fais une évaluation rapide pour trouver les ressources qui te conviennent",
  surveyLink: "https://intakeq.com/new/tzcqZn",
  start: "Début",
  browseResources: "Parcourir les ressources",
  browseHeading:
    "Consultez les ressources en matière de santé mentale disponibles en ligne, sur le campus et hors campus. Filtré selon vos besoins et vos préférences",
  viewAll: "Voir tous",
  mindfulnessResources: "Bibliothèque sur la pleine conscience",
  mindfulnessHeading:
    "Des techniques simples et pratiques, des méditations et des informations pour le bien-être mental",
  uploadWeekly: "Nous mettons en ligne de nouvelles ressources chaque semaine.",
  // My Physical Health Page
  fitnessHeading: "Choisissez comment tu as envie de bouger",
  liveStreamClasses: "Cours en direct",
  liveStreamTitle: "Cours en direct cette semaine",
  liveStreamHeading: "Entraîne-toi en temps réel avec un(e) instructeur(trice), peu importe où tu es",
  onDemandClasses: "Cours sur demande",
  onDemandHeading: "Découvrez des cours d'entraînement préenregistrés de boxe, de yoga, de pilates et plus",
  onCampusClasses: "Cours sur campus",
  onCampusHeading: "Découvrez les cours de fitness disponibles sur le campus",
  gymTime: "L'heure de gym",
  gymTimeHeading: "Réservez dans votre temps de gym ici",
  gymLink: "https://geegeereg.uottawa.ca/geegeereg/Activities/ActivitiesDetails.asp?aid=316#",

  // Navflow
  intakeQID: "tzcqZn",
  retakeSurvey: "Re faire un sondage",
  takeSurvey: "Répondre à l'enquête",
  viewRecommendationsButton: "Afficher les recommandations",
  relevanceScore: "Pertinence",
  finishedSurveyTitle: "Nous avons utilisé vos réponses pour trouver ces ressources spécialement pour vous",
  hitsExplanation1: "Vos réponses et leurs poids se multiplient pour donner un score de pertinence de ",
  hitsExplanation2: " pour cette ressource.",
  hitsExplanation3: "Vous voyez ceci parce qu'il partage ces balises avec votre enquête",


  clubs: "clubs",

  deselectAll: "tout déselectionner",
  filters: "Filtre",
  filterByLang: "Le contenu suivant vari selon le langage",
  methods: "Méthodes",
  types: "Types",
  groups: "Groupes",
  language: "Langue",
  tags: "Mots clés",
  faculty: "Faculté",
  english: "Anglais",
  french: "Français",
  bilingual: "Bilingue",
  onCampusClass: "Cours sur campus",
  onDemandClass: "Cours sur demande",
  liveStreamClass: "Cours en direct",
  classPast: "Classe passé",
  mindfulnessResource: "Ressources en matière de pleine conscience",
  resource: "Ressource",
  event: "Événement",
  eventPast: "Événement passé",
  //Miscellaneous
  readMore: "En savoir plus",
  readLess: "En savoir moins",
  viewClass: "Voir la classe",
  addToCalendar: "Ajouter à mon calendrier Google",
  sendToFriend: "Envoyer à un(e) ami(e)",
  addFavourite: "Ajouter aux favoris",
  removeFavourite: "Supprimer des favoris",
  confirmRemoveFavourite: "Êtes-vous certain(e) de vouloir enlever de vos favoris",
  yes: "Oui",
  no: "Non",
  learnMore: "Savoir plus",
  hostLink: "Lien hôte",
  amsResources: "AMS-Resources",
  nextOn: "Prochainement le $dateTimeText, voir plus de dates sur le calendrier",
  fitnessFooter:
    "Chaque corps et chaque âme exige des niveaux variables de mouvement physique - l'exercice doit être pratiqué avec modération.\nNous vous encourageons à écouter vos besoins physiques et psychologiques afin de déterminer le type et la quantité d'exercice qui vous conviennent.",
  mentalHealthFooter:
    "Ce sont des outils destinés à favoriser le bien-être mental général, mais ils ne remplacent pas les services professionnels de santé mentale.\nVeuillez vous référer au bouton d'aide d'urgence pour obtenir une aide immédiate et pointue en matière de santé mentale ou consultez votre médecin.",
  contactUsAt: "Contactez nous à  ",
  checkPrivacyPolicy: "Consultez notre Politique de confidentialité",
  register: "S'inscrire",
  pleaseRegister: "Veuillez vous inscrire",
  name: "Nom",
  email: "Courriel",
  yearOfStudy: "Niveau d'étude",
  password: "Mot de passe",
  conPassword: "Confirmer le mot de passe",
  alreadyAccount: "Tu as déjà un compte?",
  login: "Connexion",
  backtoLogin: "Retour à la page d'accueil",
  send: "Envoyer",
  notYetSignedUp: "Pas encore inscrit(e)?",
  enter: "Entrez",
  enterEmail: "Entrez votre courriel de l'Université",
  enterPassword: "Entrez votre mot de passe",
  preferredName: "Nom préféré",
  yourPreferredName: "Nom préféré",
  forgotPassword: "J'ai oublié mon mot de passe",
  emailNotFound: "Impossible de trouver le courriel",
  yourHolisticPlatform: "votre plateforme holistique de vie étudiante",
  welcomeBack: "Bienvenue",

  prev: "Préc.",
  next: "Suiv.",
  today: "Auj.",
  week: "Mois",
  month: "Semaine",
  fitness: "Aptitude",
  day: "Jour",


  selectYear: "Selectionner l'année",
  firstYear: "Première année",
  secondYear: "Deuxième année",
  thirdYear: "Troisième année",
  fourthYear: "Quatrième année",
  fifthYear: "Cinquième année et autre",

  university: "Université",
  save: "Sauvegarder",
  saved: "Sauvegardé",
  saving: "Sauvegarder",

  copyToClipboard: "Copié dans le presse-papier",
  checkOutTheLink: "Consultez le lien ici",

  // waitForRecommendations: `
  // Thank you for completing the assessment form 💙.
  // <br /><br />
  // As the <b>next step</b>, you will receive an email within the next 48 hours from our team, with a list of recommended resources based on your unique needs, wants and identity.
  // <br /><br />
  // If there is anything else you’d like to add to your form or share with our team, please email <a href="mailto:info@checkcompass.ca"><u>info@checkcompass.ca</u></a>.
  // <br /><br />
  // If you require emergency support, please go to the red emergency support button at the top of this page.
  // <br /><br />
  // Here with you,
  // <br />
  // The Compass Team
  // `

  waitForRecommendations: `
  Merci d'avoir rempli le formulaire d'évaluation 💙.
  <br /><br />
  Dans les <b>prochaines</b> 48 heures, notre équipe vous enverra un courriel contenant une liste de ressources recommandées en fonction de vos besoins, de vos souhaits et de votre identité.
  <br /><br />
  Si vous souhaitez ajouter quelque chose à votre formulaire ou le partager avec notre équipe, veuillez envoyer un courriel à <a href="mailto:info@checkcompass.ca"><u>info@checkcompass.ca</u></a>.
  <br /><br />
  Si vous avez besoin d'une aide d'urgence, veuillez cliquer sur le bouton rouge d'aide d'urgence en haut de cette page.
  <br /><br />
  Ici avec vous,
  <br />
  L'équipe Compass
  `
}

export default locales;
