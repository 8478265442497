export default class Student {
  constructor(apiStudent) {
    if (apiStudent) {
      this.name = apiStudent.name;
      this.state = apiStudent.state
      this.yearOfStudy = apiStudent.yearOfStudy;
      this.email = apiStudent.email;
    }
  }
}
