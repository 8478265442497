import { useEffect } from "react";

import { getUserIDfromCookies } from "./getUserIdFromCookie";


export default function GA4Pageview(pageName){

    useEffect(() => {
        window.dataLayer.push({
            event: 'pageview',
            user_id: getUserIDfromCookies(),
            page: {
                title: pageName,
                url: window.location.origin + window.location.pathname + window.location.hash,
            }
        });
    }, [])
    
    return pageName
}