import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Card, CardBody, Stack, Text } from "@chakra-ui/react"
import { FilterGroupPicker } from "components/RecommendationsV2/FilterGroupPicker";
import { withV2Layout } from "hoc/withLayout"
import { useCreateIntent } from "hooks/useIntent"
import { useState } from "react";

const CreateIntent = () => {
    const createIntent = useCreateIntent();
    const [isLoading, setIsLoading] = useState(false);
    
    const onClick = async () => {
        setIsLoading(true);
        const intent = await createIntent.create();
        window.location.href = `/#/recommend/${intent.id}`
        setIsLoading(false);
    }

    return (
        <Stack alignItems='center'>
            <Card
                w='40%'
                background='none'
                border='none'
                boxShadow='none'
            >
                <CardBody>
                    <Stack 
                        direction='column' 
                        p={10} 
                        spacing={6}
                        alignItems='center'
                        sx={{ backgroundColor: 'rgba(255,255,255,0.8)' }}
                        >
                            <Stack py={24} alignItems='center'>
                                <Text color='black' fontSize={32} fontWeight={900} mb={0}>Tell us about you!</Text>
                                <Text color='black' fontSize={20} fontWeight={400}>We want to match you with the best possible resources that resonate with you</Text>
                            </Stack>
                            <FilterGroupPicker useIntent={createIntent} />
                            <Stack direction='row' w='full'>
                                <Button
                                    fontSize={16}
                                    px={18}
                                    py={9}
                                    background='none'
                                    borderRadius={100}
                                    w='50%'
                                    onClick={onClick}
                                    isLoading={isLoading}
                                >
                                    <Text color='#7DC9ED'>Skip</Text>
                                </Button>
                                <Button
                                    fontSize={16}
                                    px={18}
                                    py={9}
                                    backgroundColor="#7DC9ED"
                                    borderRadius={100}
                                    w='50%'
                                    onClick={onClick}
                                    isLoading={isLoading}
                                >
                                    <Text mr={3} mb={0} color='white'>
                                        Continue
                                    </Text>
                                    <ArrowRightOutlined style={{ color: 'white' }} />
                                </Button>
                            </Stack>
                    </Stack>
                </CardBody>
            </Card>
        </Stack>
    )
}

export default withV2Layout()(CreateIntent)