import React from "react";

import sprites from "imgs/sprites.svg";
import { Modal } from "antd";

import "./CustomModal.scss";

// AntDesign Drawer Docs: https://ant.design/components/modal/
const CustomModal = ({ children, open, handleClose }) => {
  return (
    <Modal
      className="custom-modal"
      centered
      open={open}
      onCancel={handleClose}
      maskClosable={true}
      destroyOnClose={true}
      closable={true}
      footer={null}
      width={"max-content"}
    >
      <svg className="custom-modal__cross cross" onClick={() => handleClose()}>
        <use href={sprites + "#icon-cross"}></use>
      </svg>
      {children}
    </Modal>
  );
};

CustomModal.defaultProps = {
  open: true
}

export default CustomModal;
