const getBaseTranslations = (preferEnglish) => {
  return preferEnglish
    ? {
        // Nav Bar
        myCommunity: "My Community",
        myMentalHealth: "Mental Health",
        myPhysicalHealth: "My Physical Health",
        myClubs: "AMS-Clubs",
        myFavourites: "Favourites",
        emergencySupport: "Emergency Support",
        profile: "Profile",
        favourites: "Favourites",
        logout: "Logout",
        // My Community Page
        happeningToday: "What's Happening Today",
        quickLinks: "Quick Links",
        noEventsToday: "No events today",
        myCampusCalendar: "My Campus Calendar",
        // My Mental Health Page
        myRecommendedResources: "My Recommended Resources",
        recommendedHeading: "Unsure where to start? Take a quick assessment to be recommended resources right for you",
        surveyLink: "https://intakeq.com/new/wmASSL",
        start: "Start",
        browseResources: "Browse All Resources",
        browseHeading:
          "View online, on campus, and off campus mental health resources. Filtered for your needs and preferences",
        viewAll: "View All",
        mindfulnessResources: "Mindfulness Library",
        mindfulnessHeading: "Simple and actionable techniques, meditations, and information for mental wellness",
        uploadWeekly: "We upload new resources weekly.",
        // My Physical Health Path
        fitnessHeading: "Choose how you feel like moving",
        liveStreamClasses: "Livestream Classes",
        liveStreamTitle: "Livestreams Classes this Week",
        liveStreamHeading: "Workout real-time with an instructor, wherever you are",
        onDemandClasses: "On-Demand Classes",
        onDemandHeading: "Explore pre-recorded workout classes from boxing, yoga, pilates and more",
        onCampusClasses: "On-Campus Classes",
        onCampusHeading: "Check out what fitness classes are available on campus",
        gymTime: "Gym Time",
        gymTimeHeading: "Book your gym time here",
        gymLink: "https://geegeereg.uottawa.ca/geegeereg/Activities/ActivitiesDetails.asp?aid=316#",
        gymLinkQueens: "https://getactive.gogaelsgo.com/Program/GetProducts?classification=b2e9f15b-dbaa-4f55-8bb3-6c1ca1c00e32",

        //AMS-Clubs Path
        browseAllClubs: "Browse All Clubs",
        browseAllClubsHeading: "Browse All Clubs",
        browseAllClubsDescription: "Looking to get involved? Browse all clubs and filter based on what you’re looking for!",
        browseAllClubsResources: "Browse All Clubs Resources",
        browseAllClubsResourcesDescription: "Looking for clubs resources like grants, room bookings, clubs manual and more? Browse all resources here!",
        governance: "Governance",
        clubs: "AMS-Clubs",

        filters: "Filters",
        filterByLang: "The following content varies by language",
        filterNames: ["Types", "Methods", "Groups"], //must be in this order
        clubsFilterNames: ["Types", "Faculty"],
        clubsResourcesFilterNames: ["Types"],
        tags: "Tags",
        types: "Types",
        faculty: "Faculty",
        language: "Language",
        languageFilters: [
          { label: "English", value: "en" },
          { label: "French", value: "fr" },
          { label: "Bilingual", value: "bi" },
        ],
        location: "Location",
        locationFilters: [
          { label: "Online", value: "online" },
          { label: "On Campus", value: "oncampus" },
          { label: "Off Campus", value: "offcampus" },
        ],
      }
    : {
        // Nav Bar
        myCommunity: "Ma communauté",
        myMentalHealth: "Ma santé mentale",
        myPhysicalHealth: "Mon bien-être physique",
        myFavourites: "Mes Favoris",
        emergencySupport: "Soutien d'urgence",
        profile: "Profil",
        favourites: "Favoris",
        logout: "Déconnexion",
        // My Community Page
        happeningToday: "Ce qui se passe aujourd'hui",
        quickLinks: "Lien rapides",
        noEventsToday: "Pas d'événements aujourd'hui",
        myCampusCalendar: "Le calendrier de mon campus",
        // My Mental Health Page
        myRecommendedResources: "Mes ressources recommandées",
        recommendedHeading:
          "Tu ne sais pas par où commencer ? Fais une évaluation rapide pour trouver les ressources qui te conviennent",
        surveyLink: "https://intakeq.com/new/tzcqZn",
        start: "Début",
        browseResources: "Parcourir les ressources",
        browseHeading:
          "Consultez les ressources en matière de santé mentale disponibles en ligne, sur le campus et hors campus. Filtré selon vos besoins et vos préférences",
        viewAll: "Voir tous",
        mindfulnessResources: "Bibliothèque sur la pleine conscience",
        mindfulnessHeading:
          "Des techniques simples et pratiques, des méditations et des informations pour le bien-être mental",
        uploadWeekly: "Nous mettons en ligne de nouvelles ressources chaque semaine.",
        // My Physical Health Page
        fitnessHeading: "Choisissez comment tu as envie de bouger",
        liveStreamClasses: "Cours en direct",
        liveStreamTitle: "Cours en direct cette semaine",
        liveStreamHeading: "Entraîne-toi en temps réel avec un(e) instructeur(trice), peu importe où tu es",
        onDemandClasses: "Cours sur demande",
        onDemandHeading: "Découvrez des cours d'entraînement préenregistrés de boxe, de yoga, de pilates et plus",
        onCampusClasses: "Cours sur campus",
        onCampusHeading: "Découvrez les cours de fitness disponibles sur le campus",
        gymTime: "L'heure de gym",
        gymTimeHeading: "Réservez dans votre temps de gym ici",
        gymLink: "https://geegeereg.uottawa.ca/geegeereg/Activities/ActivitiesDetails.asp?aid=316#",
        clubs: "clubs",

        filters: "Filtre",
        filterByLang: "Le contenu suivant vari selon le langage",
        filterNames: ["Types", "Méthodes", "Groupes"], //must be in this order
        libraryFilterNames: ["Sujet", "Média"],
        language: "Langue",
        tags: "Mots clés",
        faculty: "Faculté",
        languageFilters: [
          { label: "Anglais", value: "en" },
          { label: "Français", value: "fr" },
          { label: "Bilingue", value: "bi" },
        ],
        location: "Lieu",
        locationFilters: [
          { label: "En ligne", value: "online" },
          { label: "Sur le campus", value: "oncampus" },
          { label: "Hors campus", value: "offcampus" },
        ],
      };
};

export default getBaseTranslations;
