import React, { useContext } from "react";

import { Select } from "antd";
import LocaleContext from "contexts/locale";

const { Option } = Select;

const LanguageToggle = (supportedLanguages) => {
  const { setLocale, locale, locales, t } = useContext(LocaleContext)

  const langs = supportedLanguages.lang

  if (langs && (langs.length > 1)){
    return (
      <Select
      defaultValue="en-US"
      value={t(locale)}
      style={{ width: 120 }}
      onChange={setLocale}
    >
      {locales.map(_locale => {
        return (
          <Option key={_locale} value={_locale}>{t(_locale)}</Option>
        )
      })}
    </Select>
    )
  }

  return (<></>)
  
};

export default LanguageToggle;
