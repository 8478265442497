//Image
import CP from "imgs/navbar/master-logo.png";
import Spinner from "components/Spinner/Spinner";
import { Helmet } from "react-helmet"
import GA4Pageview from "../../util/pageview"

const LoadingPage = () => {

  const pageTitle = GA4Pageview("Loading...")
  
  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
        </Helmet>
        <div className="loading-page">
        <img src={CP} alt={"logo"} />
        <Spinner size="4rem" />
        </div>
    </>
  );
};

export default LoadingPage;
