import axios from "axios";

import client from "./client";
import { BASE_URL } from "util/config";

import Resource from "models/resource";
import RecommendedResource from "models/recommendedresource";
import ResourceDate from "models/resource-date";
import ResourceTags from "models/resource-tags";
import Student from "models/student";
import Org from "models/org";
import Favourite from "models/favourite";
import Recommendation from "models/recommendation";

const FETCH_ALL_PAGE_LIMIT = 100;
const DEFAULT_RESOURCES_PAGE_SIZE = 10;
class BaseApi {
  // This method fetches all the pages for a certain query
  async _fetchAll(url, params) {
    let result = []
    let pageSize = FETCH_ALL_PAGE_LIMIT;
    let page = 0

    for (page = 0; pageSize === FETCH_ALL_PAGE_LIMIT; page++) {
      params.page = page;
      params.limit = FETCH_ALL_PAGE_LIMIT;
      pageSize = 0
      const res = await client.get(url, params);

      if (res && res.data) {
        pageSize = res.data.items.length
        result = result.concat(res.data.items)
      }
    }

    return {
      data: {
        items: result,
        total: result.length
      }
    }
  }

  async _get(url, params, options) {
    let result = {}
    if (options.fetchAll) {
      result = await this._fetchAll(url, params)
    } else {
      result = await client.get(url, params)
    }
    return result
  }

  async _getResources(type, params = {}, options = {}) {
    params.types = type || params.types;
    params.limit = params.limit || DEFAULT_RESOURCES_PAGE_SIZE;
    params.search = params.search || ''

    const result = await this._get("/resources", params, options)

    return {
      data: Resource.fromArray(result.data.items),
      total: result.data.total
    }
  }

  async _getRecommendedResources(type, params = {}, options = {}){
    params.types = type || params.types;
    params.limit = params.limit || DEFAULT_RESOURCES_PAGE_SIZE;

    const result = await this._get("/recommendedresources", params, options)
    console.log("ran _getRecommendedResources on frontend got this:", result)
    return {
      data: RecommendedResource.fromArray(result.data.items),
      total: result.data.total
    }
  }

  async _getResourceTags(type, params = {}, options = {}) {
    params.types = type || params.types;

    const result = await this._get("/resourcetags", params, options)

    return {
      data: new ResourceTags(result.data.items),
      total: result.data.total
    }
  }
}

class Resources extends BaseApi {
  DEFAULT_PAGE_SIZE = DEFAULT_RESOURCES_PAGE_SIZE
  CALENDAR_RESOURCE_TYPES = [
    Resource.TYPES.EVENT
  ]

  async getAllResources(params = {}, options = {}) {
    return await this._getResources('', params, options)
  }

  async getAllRecommendedResources(params = {}, options = {}){
    return await this._getRecommendedResources(Resource.TYPES.MENTAL_HEALTH, params, options)
  }

  async getEventsWithinRange(startDate, endDate, params = {}, options = {}) {
    params.types = [Resource.TYPES.EVENT]

    const result = await this._get(`/resourcedates/start/${startDate}/end/${endDate}`, params, options)

    if (result.data.items) {
      return {
        data: ResourceDate.fromArray(result.data.items)
      }
    }

    return { data: [] }
  }

  async getCalendarResources(startDate, endDate, params = {}, options = {}) {
    params.types = this.CALENDAR_RESOURCE_TYPES

    const result = await this._get(`/resourcedates/start/${startDate}/end/${endDate}`, params, options)


    if (result.data.items) {

      return {
        data: ResourceDate.fromArray(result.data.items)
      }
      
    }

    return { data: [] }
  }

  async getCalendarResourceTags(params = {}, options = {}) { 
    params.types = this.CALENDAR_RESOURCE_TYPES

    return await this._getResourceTags('', params, options)
  }

  async getMentalHealthResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.MENTAL_HEALTH, params, options)
  }

  async getMentalHealthResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.MENTAL_HEALTH, params, options);
  }

  async getMindfulnessResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.MINDFULNESS, params, options)
  }

  async getMindfulnessResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.MINDFULNESS, params, options);
  }

  async getMessageOfTheDayResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.MESSAGE_OF_THE_DAY, params, options)
  }

  async getOnDemandResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_ON_DEMAND, params, options)
  }

  async getOnDemandResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.FITNESS_ON_DEMAND, params, options);
  }

  async getOnCampusResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_ON_CAMPUS, params, options)
  }

  async getOnCampusResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.FITNESS_ON_CAMPUS, params, options);
  }

  async getClubResourceResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.CLUB_RESOURCE, params, options)
  }

  async getClubResourceResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.CLUB_RESOURCE, params, options);
  }

  async getClubResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.CLUB, params, options)
  }

  async getClubResourceTags(params = {}, options = {}) {
    return await this._getResourceTags(Resource.TYPES.CLUB, params, options);
  }

  async getEventResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.EVENT, params, options)
  }

  async getQuickLinkResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.QUICK_LINK, params, options)
  }

  async getLiveStreamResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_LIVE_STREAM, params, options)
  }
}

class Recommendations extends BaseApi {

  async logrec(instance){
      const response = await client.post(`/recommendationslog/${instance}`)
      console.log("logrec reponse", response)
      return true
  }

  // At the moment this is only used for browser notifications
  async getAllRecommendations(params = {}, options = {}){
      const response = await this._get('/recommendations', params, options)
      const result = Recommendation.fromArray(response.data.items);
  
      return {
          data: result,
          total: result.length
        }
    }
}

class Orgs extends BaseApi {
    async getOrg(params = {}, options = {}) {
      const response = await this._get('/getorg', params, options)
      return {
        data: new Org(response.data)
      }
    }
  
    async updateProfile(body = {}) {
      return await client.post('/users/profile', body)
    }
  }

class Students extends BaseApi {
  async getProfile(params = {}, options = {}) {
    const response = await this._get('/users/profile', params, options)
    return {
      data: new Student(response.data)
    }
  }

  async updateProfile(body = {}) {
    return await client.post('/users/profile', body)
  }
}

class Favourites extends BaseApi {
  async getAllFavourites(params = {}, options = {}) {
    const response = await this._get('/studentresources', params, options)
    const result = Favourite.fromArray(response.data.items);

    return {
      data: result,
      total: result.length
    }
  }

  async getFavourite(resourceId) {
    const response = await client.get(`/studentresources/${resourceId}`)

    return {
      data: new Favourite(response.data),
    }
  }
  
  async addFavourite(resourceId) {
    const response = await client.put(`/studentresources/${resourceId}`)
    
    return {
      data: response.data,
    }
  }

  async removeFavourite(studentresourceId) {
    const response = await client.delete(`/studentresources/${studentresourceId}`)

    return {
      data: response.data,
    }
  }
}

class Tokens extends BaseApi {
  async getToken(uuid) {
    const instance = axios.create({
      baseURL: BASE_URL
    });

    try {
        return await instance.get(`/oauth2/auth/accesstoken/${uuid}`)
    } catch (e){
        console.log(e.response.status)
        return e.response
    }

  }
}

export const resourcesApi = new Resources()
export const studentApi = new Students()
export const orgApi = new Orgs()
export const favouritesApi = new Favourites()
export const recommendationsApi = new Recommendations()
export const tokensApi = new Tokens();
