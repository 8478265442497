import { withLayout } from "hoc/withLayout";
import React, { useCallback, useContext, useState, useEffect } from "react";


const GoBack = () => {
    localStorage.setItem('userHasTakenSurvey', 'true')
    window.history.go(-2)
    //window.close()

  return (
    <div> Hello ... </div>
  )
};

export default withLayout()(GoBack);
