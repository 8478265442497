import groupBy from "lodash/groupBy";

import BaseModel from "./base-model";
import ResourceTag from "./resource-tag";


class ResourceTags extends BaseModel {
  constructor(apiTags = [], locale) {
    super()
    this.tags = apiTags.map(apiTag => new ResourceTag(apiTag))
    this.locale = locale || this.DEFAULT_LOCALE;
  }

  getGroupedLocaleTags = (locale = this.DEFAULT_LOCALE) => {
    return this._getGroupedLocaleTags(locale || this.locale)
  }
  
  _getGroupedLocaleTags = (locale = this.DEFAULT_LOCALE) => {
    return groupBy(this.tags, tag => {
      return this._extract_locale_text_from_obj(tag.groupObj, locale)
    })
  }

  getTags() {
    return this.tags;
  }

  setLocale(locale) {
    this.locale = locale;
  }
}

export default ResourceTags;