
// ROUTES
export const routes = {
  // AUTH
  register: "/studentView/register/",
  activate: (id, token) => `/studentView/activate/${id}/${token}/`,
  findUser: (email) => `/studentView/finduser/${email}/`,
  resetPassword: "/auth/password/reset/",
  confirmResetPassword: "/auth/password/reset/confirm/",
  login: "/auth/login/",
  // STUDENT
  studentGet: "/studentView/student/",
  studentUpdate: "/studentView/student/update/",
  studentUpdateFav: "/studentView/student/update-fav/",
  // DATA
  links: "/studentView/links/",
  messageOfTheDay: "/studentView/messageoftheday/",
  // TAGS
  eventTags: "/studentView/eventtag/",
  eventFaculty: "/studentView/eventfaculty/",
  onDemandTags: "/studentView/odtag/",
  resTags: "/studentView/restag/",
  resMethods: "/studentView/resmethod/",
  resGroups: "/studentView/resgroup/",
  amsFaculty: "/studentView/amsfaculty/",
  amsType: "/studentView/amstype/",
  amsResourceType: "/studentView/amsresourcetype",
  // ITEMS
  mindfulness: (preferred_language) => `/studentView/mindfulness?language=${preferred_language}`,
  events: (filteredUrl) => `/studentView/events?${filteredUrl}`,
  eventsToday: "/studentView/eventstoday/",
  onCampus: (currMonday) => `/studentView/oncampus?monday=${currMonday.format("YYYY-MM-DD")}`,
  onDemand: (filteredUrl) => `/studentView/ondemand/${filteredUrl}`,
  resources: (filteredUrl) => `/studentView/res/?${filteredUrl}`,
  clubs: (filteredUrl) => `/studentView/amsclubs/?${filteredUrl}`,
  amsResources: (filteredURL) => `/studentView/amsresource/?${filteredURL}`,
  livestreams: (monday) => `/studentView/livestream?monday=${monday.format("YYYY-MM-DD")}`,
  onCampusAll: "/studentView/oncampusall/", //route to get all
  livestreamAll: "/studentView/livestreamall", //route to get all
};

export const favConstants = {
  onDemand: "fav_ondemand",
  onCampus: "fav_oncampus",
  livestream: "fav_livestream",
  resource: "fav_resource",
  club: "fav_clubs",
  clubResources: "fav_clubResources",
  event: "fav_event",
  mindfulness: "fav_mindfulness",
};

export const loginErrors = {
  mustNotBlank: "This field may not be blank.",
  passTooShort: "This password is too short. It must contain at least 8 characters.",
  needEightChar: "Ensure this field has at least 8 characters.",
  passTooCommon: "This password is too common.",
  mustMatch: "Passwords must match",
  passFieldsMatch: "The two password fields didn’t match.",
  useSchoolEmail: "Please use your school email to register",
  emailTaken: "Has already been taken by other user",
  validEmail: "Please enter a valid email address",
}

export const COOKIE_AUTH_NAME = "compass.user.token";
export const AUTH_UUID_COOKIE_NAME = "compass.token.uuid";