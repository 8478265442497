import { formatDate } from "@fullcalendar/core";
import moment from "moment";
import store from "redux/_store";

export const getCalendarLink = (title, description, link, date, start, end, host_link) => {
  let full_descript = description;
  if (link){
    full_descript += '<br><br> Link: <a href=' + link + '>' + link+ '</a>';
  }
  if (host_link){
    full_descript += '<br> Host Link: <a href=' + host_link + '>' + host_link + '</a>'; 
  }
  let calLink = `https://calendar.google.com/calendar/r/eventedit?text=${title}&details=${full_descript}`;
  if (date) {
    calLink += getCalendarDate(date, start, end);
  }
  calLink += "&sf=true&output=xml";
  return calLink;
};

const getCalendarDate = (date, start, end) => {
  // Find time difference in hours from our timezone to one used by Google Calendar
  let hoursDifference = new Date().getTimezoneOffset() / 60;
  console.log(hoursDifference)
  let dateString = moment(date).format("YYYYMMDD");
  let startMoment = moment(start, "HH-mm-ss").add(hoursDifference, "hours");
  let endMoment = moment(end, "HH-mm-ss").add(hoursDifference, "hours");
  // Combine all strings into required format
  // ie. June 14, 2020 5pm to June 14, 2020 7pm -> 20200614T170000Z20200614T190000
  let startString = `${dateString}T${startMoment.format("HHmmss")}Z`;
  let endString = `${dateString}T${endMoment.format("HHmmss")}Z`;
  return `&dates=${startString}/${endString}`;
};

export const getTimeRange = (start, end) => {
  let timeRange = moment(start).format("h:mmA");
  if (end !== "Invalid Date") timeRange += " - " + moment(end).format("h:mmA");
  return timeRange;
};

// Returns formatted date string in correct language
// ie. 20200711 -> Thursday, July 11
export const getDateTimeText = (date, start, end) => {
  let timeRange = getTimeRange(start, end);

  let locale = store.getState().student.preferred_language;
  let dateText = formatDate(date, {
    day: "numeric",
    weekday: "long",
    month: "long",
    locale,
  });
  return `${dateText}, ${timeRange}`;
};

// Returns dates starting on the give monday in array of custom date information for use in Class Calendars
// Date represented in array as { date: "Tue 9.15", hasPast: true, dateString: "2020-09-15"}
export const getDatesForWeek = (monday) => {
  let days = [];
  for (let i = 0; i <= 6; i++) {
    days.push(monday.clone().add(i, "days"));
  }

  days = days.map((day) => {
    return {
      date: day.format("ddd M.DD"),
      hasPast: day.isBefore(moment().subtract(1, "days")),
      dateString: day.format("YYYY-MM-DD"),
    };
  });
  return days;
};

// Returns the next upcoming date in an array of date strings
export const getFirstDate = (datesStringArray) => {
  datesStringArray.sort((a, b) => {
    return moment(a).isAfter(b);
  });
  let date = datesStringArray[0];
  if (date) {
    let dateMoment = moment(date, "YYYY-MM-DDTHH-mm-ss");
    if (dateMoment.isBefore(moment().subtract(1, "days"))) {
      return null;
    } else {
      return date;
    }
  }

  return datesStringArray[0];
};

// Creates an individual event object to appear on calendar for each date that an event has
// As well as only keeps information that is required in helping the user view the event
export const formatEvents = (events, type) => {
  let lang = store.getState().student.preferred_language;

  let formattedEvents = [];
  for (let event of events) {
    if (event.is_Approved){ //only return approved events
      for (let date of event.dates) { //for multiple-times array, you'll need to do another nested loop that checks the length of this array and see if there is more recurring times.
        date = moment(date).format("YYYY-MM-DD");
        let start = `${date}T${event.start_time}`;
        let end = `${date}T${event.end_time}`;
        let { end_time, start_time, ...eventData } = event;
        let title = lang === "en" ? event.title_en : event.title_fr;
        let newEvent = { ...eventData, date, start, end, title, type };
        formattedEvents = [...formattedEvents, newEvent];
      }
    }
  }
  return formattedEvents;
};


export const formatOnlyEvents = (events, type) => { //only for Events (to access multiple_times array in backend)
  let lang = store.getState().student.preferred_language;

  let formattedEvents = [];
  for (let event of events) {
    if (event.is_Approved){ //only return approved events
      for (let date of event.dates) { //for multiple-times array, you'll need to do another nested loop that checks the length of this array and see if there is more recurring times.
        let multipleTimes = [{ start_time: event.start_time, end_time: event.end_time }, ...event.multiple_times]
        for (let time of multipleTimes){ //access the multiple start/end times inside the array multiple_times
          date = moment(date).format("YYYY-MM-DD");
          let start = `${date}T${time.start_time}`; //sets the multiple start-time
          let end = `${date}T${time.end_time}`;  //sets the multiple end-time
          let { end_time, start_time, ...eventData } = event;
          let title = lang === "en" ? event.title_en : event.title_fr;
          let newEvent = { ...eventData, date, start, end, title, type };
          formattedEvents = [...formattedEvents, newEvent];
        }
      }
    }
  }
  return formattedEvents;
};



export const containsDate = (datesStringArray, date) => {
  return (
    datesStringArray.filter(
      ((item) => {
        return item.startsWith(date);
      }) || []
    ).length > 0
  );
};
