import React, { useContext, useState } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

import cpLogoName from "imgs/navbar/master-logo.png";
import profile from "imgs/navbar/profile.png";
 
import AuthContext from "contexts/auth";
import LocaleContext from "contexts/locale";

import sprites from "imgs/sprites.svg";

import Emergency from "./Emergency/Emergency";
import Profile from "./Profile/Profile";

import LanguageToggle from "../LanguageToggle/LanguageToggle";

import CustomModal from "../CustomModal/CustomModal";
import CustomDrawer from "../CustomDrawer/CustomDrawer";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";

import "./NavBar.scss";

const NavBar = ({user, org}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout } = useContext(AuthContext);
  const { t } = useContext(LocaleContext)
  const history = useHistory();

  const [profileOpen, setProfileOpen] = useState(false);
  const [emergencyOpen, setEmergencyOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  console.log(org)

  // TODO: Replace this with a check from the server
  // This is where we're getting the language bar stuff
  // Hello!
  // Okay lets add the supported langage field of orgs to where it can be sent to the frontend
  const canShowLangToggle = !window.location.href.includes('mywellness') && !window.location.href.includes('mcgill')
  const whitelabel = org.snowflake && org.snowflake.logo ? org.snowflake.logo : cpLogoName

  const tabs = [
    {
      path: "/home",
      text: t("myCommunity"),
    },
    {
      path: "/mental-health",
      text: t("myMentalHealth"),
    },
    {
      path: "/favourites",
      text: t("favourites")
    }
  ];

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const CustomMenu = () => {
    return (
      <Menu className="nav-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
        <MenuItem
          onClick={() => {
            closeMenu();
            setProfileOpen(true);
          }}
        >
          {t("profile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            closeMenu();
            history.push("/favourites");
          }}
        >
          {t("favourites")}
        </MenuItem>
        <MenuItem
          onClick={() => {

            window.dataLayer.push({
                event: "event",
                user_id: getUserIDfromCookies(),
                eventProps: {
                    category: "logout",
                    action: "click",
                    label: `User logged out`,
                    transport: "beacon"
                }
            })

            closeMenu();
            logout();
          }}
        >
          {t("logout")}
        </MenuItem>
      </Menu>
    );
  };

  return (
    <div className="navbar">
      <button className="navbar__menu-icon" onClick={() => setDrawerOpen(true)}>
        <svg>
          <use href={sprites + "#icon-menu"}></use>
        </svg>
      </button>

      <Link className="navbar__logo-container" to="/mental-health">
        <img className={`navbar__logo-name ${org && org.snowflake && org.snowflake.logoClass ? org.snowflake.logoClass : ''}`} src={whitelabel} alt="logo" />
      </Link>

      <div className="navbar__links">
        {tabs.map((tab, index) => (
          <NavLink className="navbar__link" activeClassName="navbar__link--active" key={index} to={tab.path}>
            {tab.text}
          </NavLink>
        ))}
      </div>

      <button className="navbar__emergency button--red button--nav" onClick={() => setEmergencyOpen(true)}>
        {t("emergencySupport")}
      </button>
        
      {canShowLangToggle && <LanguageToggle lang={org.supportedLanguages}/>}

      <button className="navbar__profile button--blue button--nav button--profile" onClick={openMenu}>
        <img src={profile} alt="Menu" />
      </button>
      <CustomMenu />

      {profileOpen && (
        <CustomModal handleClose={() => setProfileOpen(false)}>
          <Profile user={user} org={org} closeProfile={() => setProfileOpen(false)}/>
        </CustomModal>
      )}
      {emergencyOpen && (
        <CustomModal handleClose={() => setEmergencyOpen(false)}>
          <Emergency org = {org} />
        </CustomModal>
      )}

      <CustomDrawer placement={"left"} open={drawerOpen} handleClose={() => setDrawerOpen(false)}>
        <div className="navbar__drawer">
          {tabs.map((tab, index) => (
            <NavLink
              className="navbar__drawer-link"
              activeClassName="navbar__drawer-link--active"
              onClick={() => setDrawerOpen(false)}
              key={index}
              to={tab.path}
            >
              {tab.text}
            </NavLink>
          ))}
          <NavLink
            className="navbar__drawer-link"
            activeClassName="navbar__drawer-link--active"
            onClick={() => setDrawerOpen(false)}
            to={"/favourites"}
          >
            {t("myFavourites")}
          </NavLink>

          <button
            className="navbar__drawer-link"
            onClick={() => {
              setProfileOpen(true);
              setDrawerOpen(false);
            }}
          >
            {t("profile")}
          </button>

          <button className="navbar__drawer-logout" onClick={() => logout()}>
            {t("logout")}
          </button>
        </div>
      </CustomDrawer>
    </div>
  );
};

export default NavBar;
