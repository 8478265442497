export default class Org {

    snowflake;

    constructor(apiOrg) {
      if (apiOrg) {
        this.snowflake = apiOrg.snowflake;
        this.appId = apiOrg.appId;
        this.gymTimeLink = apiOrg.gymTimeLink;
        this.name = apiOrg.name;
        this.supportedLanguages = apiOrg.supportedLanguages;
        this.emergencySupportInfo = apiOrg.emergencySupportInfo
      }
    }
  }
  