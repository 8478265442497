import React from "react";

import sprites from "imgs/sprites.svg";
import { Drawer } from "antd";

import "./CustomDrawer.scss";

// AntDesign Drawer Docs: https://ant.design/components/drawer/
const CustomDrawer = ({ children, handleClose, open, className, hideX, placement = "right" }) => {
  return (
    <Drawer
      className={`custom-drawer ${className}`}
      placement={placement}
      onClose={handleClose}
      open={open}
      maskClosable={true}
      closable={false}
      width={"max-content"}
    >
      {!hideX && (
        <svg className="custom-drawer__cross cross" onClick={() => handleClose()}>
          <use href={sprites + "#icon-cross"}></use>
        </svg>
      )}
      {children}
    </Drawer>
  );
};

export default CustomDrawer;
