import { FilterGroup, Intent } from "api/group";
import { Result, intentV2Api } from "api/intent";
import { useEffect, useState } from "react";

export const useIntent = (id?: string) => {
    const [intent, setIntent] = useState<Intent>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<FilterGroup[]>([]);
    const [result, setResult] = useState<Result>([]);
    
    const init = async () => {
        if (!id) return;

        setIsLoading(true);
        const intent = await intentV2Api.getIntent(id);
        setIntent(intent);
        setSelectedFilterGroups(intent.filterGroups);

        setIsLoading(false);
    }

    const update = async (filterGroupIds: string[]) => {
        if (!intent) return;

        setIsLoading(true);
        const updatedIntent = await intentV2Api.update(intent.id, filterGroupIds);
        setIntent(updatedIntent);

        setIsLoading(false);
    }

    const search = async () => {
        if (!intent) return;

        setIsLoading(true);
        const result = await intentV2Api.search(intent.id);
        setResult(result);

        setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, [id]);

    useEffect(() => {
        if (!selectedFilterGroups) return;
        const filterGroupIds = selectedFilterGroups.map(fg => fg.id);
        update(filterGroupIds);
    }, [selectedFilterGroups])

    useEffect(() => { search(); }, [intent]);

    return {
        isLoading,
        intent,
        selectedFilterGroups,
        setSelectedFilterGroups,
        result
    }
}

export const useCreateIntent = () => {
    const [selectedFilterGroups, setSelectedFilterGroups] = useState<FilterGroup[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const create = async () => {
        setIsLoading(true);

        const filterGroupIds = selectedFilterGroups.map(fg => fg.id);
        const intent = await intentV2Api.create(filterGroupIds);
        
        setIsLoading(false);
        return intent;

    }

    return {
        isLoading,
        selectedFilterGroups,
        setSelectedFilterGroups,
        create
    }
}