import React, { useCallback, useContext, useState, useEffect, useMemo } from "react";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"
import useDebounce from "util/useDebounce";

import { Input } from 'antd';

import LocaleContext from "contexts/locale";

import ResourcesList from "pages/_common/ResourcesList";

import { resourcesApi } from "api";

import "./browse.scss";

import cookies from "js-cookie"
import { withLayout } from "hoc/withLayout";

const Browse = () => {
  // continue making changes
  const pageTitle = GA4Pageview("Browse All Resources")

  const allTag = '0879982d-2544-45ef-a2f9-fddd8175d8c0'
  
  const [loadingResources, setLoadingResources] = useState(true)
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([])
  // const [appliedFilters, setAppliedFilters] = useState({tags: [allTag]});
  const [appliedFilters, setAppliedFilters] = useState({});
  
  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)

  // This is starting from 1 because the pagination component starts its page at 1
  const [page, setPage] = useState(1);
  const [pages,setPages] = useState(0)
  
  const { t } = useContext(LocaleContext);

  const getGroupTagIdsMemo = useMemo(() => {
    return (method) => {

      const allTags = tags.tags
      const methodTags = []
      for (const tag of allTags){
        if (tag.groupObj["en-US"].text === method){
          methodTags.push(tag)
        }
      }

      return methodTags.map(groupTag => groupTag.id)

    }
  }, [tags])

  // This setState interceptor makes sure that only one Method tag can be selected at a time
  const setAppliedFiltersInterceptor = (stateInput) => {

    const singleSelectMethods = ["Methods", "Types"]

    const newFilters = []
    const removedTagIds = []

    for (const method of singleSelectMethods){
      const methodTagIds = getGroupTagIdsMemo(method)
      const foundMethodTags = []
      stateInput.tags?.forEach(f => {
        if (methodTagIds.includes(f)){
          removedTagIds.push(f)
          foundMethodTags.push(f)
        }
      })
      // If any of the tags WERE from the selectOne groups, add the last one selected to the newFilters
      if (foundMethodTags.length){
        newFilters.push(foundMethodTags.pop())
      }
    }

    // And then fill in the newFilters with all the other default "multiple select" groups (excluding all others from the singleSelect)
    stateInput.tags?.forEach(f => {
      if (!removedTagIds.includes(f)){
        newFilters.push(f)
      }
    })

    stateInput.tags = newFilters

    setAppliedFilters(stateInput)
  }

  const bns = cookies.get("bns")
  if (!bns.includes("_ba_")){
    cookies.set("bns", bns.replace("_ba_", "_"))
  }

  useEffect(() => {
    async function fetchData() {
      setLoadingResources(true);

      try {
        const result = await resourcesApi.getMentalHealthResources(
          {...appliedFilters,
            page: page - 1,
            search: debouncedSearch
          })

        setResources(result.data);
        setPages(result.total)
      } catch(e) {}

      setLoadingResources(false);
    }
    fetchData();
  }, [appliedFilters, page, pages, debouncedSearch]);

  useEffect(() => {
    (async () => {
      try {
        const result = await resourcesApi.getMentalHealthResourceTags()
        setTags(result.data)
      } catch(e) {}
    })()
  }, [])

  const onPageChange = useCallback((page) => {
    setPage(page)
  }, [])

  const renderTitle = useCallback(() => {
    return (
      <div className="list-page__title">{t("browseResources")}</div>
    )
  }, [t])


  return (
    <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
        </Helmet>
        

        <Input
        className = "searchbar"
        placeholder="search"
        value = {search}
        onChange={e => setSearch(e.target.value)} />

        <ResourcesList
        appliedFilters={appliedFilters}
        onPageChange={onPageChange}
        tags={tags}
        loadingResources={loadingResources}
        total={pages}
        // updateAppliedFilters={setAppliedFilters}
        updateAppliedFilters={setAppliedFiltersInterceptor}
        currentPage={page}
        resources={resources}
        renderTitle={renderTitle}
        showCalendarLink
        setPage={setPage}
        />

    </>
  )
};

export default withLayout()(Browse);
