import React, { useContext } from "react";
import { Link } from "react-router-dom";
import SurveyRedirectButton from "./SurveyRedirectButton";
import GA4Pageview from "../../util/pageview"

import { Helmet } from "react-helmet";

import backgroundBlue from "imgs/mental-health/background-blue.svg";
import backgroundGrey from "imgs/mental-health/background-grey.svg";
import articles from "imgs/mental-health/articles.png";
import scale from "imgs/mental-health/scale.png";

import cpLogo from "imgs/navbar/ww-logo.png";

import LocaleContext from "contexts/locale";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";


import "./index.scss";
import { withLayout } from "hoc/withLayout";

const MentalHealth = () => {

  const pageTitle = GA4Pageview("Mental Health")

  const { t, locale } = useContext(LocaleContext);

  const recommendationsPageButtonHandler = (e) => {
      window.dataLayer.push({
          event: 'event',
          user_id: getUserIDfromCookies(),
          eventProps: {
              category: "navflow",
              action: "click",
              label: `redirect to: recommendations page`,
              value: "7000", // navflow can be 7000
              transport: 'beacon' // this is supposedly for GA4 when doing an outbound redirect
              //nonInteraction: true // this is for events where the user didn't interact I guess...?
          }
      });
  }

  const linkRenderer = (tab) => {
      let link
      if (tab.backend){
          link = 
          <div className = "recommended-resources-options">
              <SurveyRedirectButton text={t("takeSurvey")} locale={locale}/>
          </div>
      } else if (tab.linksOut){
          link=
          <a href={tab.link} rel="noopener noreferrer">
              <button className="tab__button">{tab.button}</button>
          </a>
      } else {
          link=
          <Link to={tab.link}>
              <button className="tab__button">{tab.button}</button>
          </Link>
      }
      return link
  }

    // axios.defaults.baseURL = `${INTAKEQ_BASE_URL}/api/v1`

  const tabs = [ 
  {
      title: t("myRecommendedResources"),
      description: t("recommendedHeading"),
      button: t("start"),
      back: backgroundBlue,
      icon: cpLogo,
      backend: true,
  },
  {
      title: t("browseResources"),
      description: t("browseHeading"),
      button: t("viewAll"),
      icon: articles,
      link: "mental-health/all-resource",
  },
  {
      title: t("mindfulnessResources"),
      description: t("mindfulnessHeading"),
      button: t("viewAll"),
      back: backgroundGrey,
      icon: scale,
      link: "mental-health/mindfulness-resource",
  }
  ];

    return (
        <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
        </Helmet>

        <div className="mental-health">
            {tabs.map((tab, index) => (
            <div className="tab-container" style={{ backgroundImage: `url(${tab.back})` }} key={index}>
                <div className="tab__info">
                <img className="tab__img" src={tab.icon} alt={tab.title} />
                <div className="tab__title heading-secondary">{tab.title}</div>
                <div className="tab__description">{tab.description}</div>
                </div>
                {linkRenderer(tab)}
            </div>
            ))}
            <div className="mental-health__banner">
            <span className="text-banner">{t("mentalHealthFooter")}</span>
            </div>
        </div>
    </>
    );
};

export default withLayout()(MentalHealth);
