import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"

import { Tabs } from "antd";

import Resource from "components/Items/Resource";
import NoResources from "components/Items/NoResources";

import { favouritesApi } from "api";

import LocaleContext from "contexts/locale";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";

import "./Favourites.scss";
import { withLayout } from "hoc/withLayout";

const { TabPane } = Tabs;


const Favourites = () => {

  const pageTitle = GA4Pageview("Favourites")
  const [favourites, setFavourites] = useState([])

  const { t } = useContext(LocaleContext)

  const fetchFavourites = async () => {
    try {
      const result = await favouritesApi.getAllFavourites({}, { fetchAll: true })
      setFavourites(result.data)
    } catch(e) {}
  }

  const onAddFavouriteResource = useCallback(async resource => {
    await favouritesApi.addFavourite(resource.id)
    await fetchFavourites()
    window.dataLayer.push({
        event: "resource_favourited",
        user_id: getUserIDfromCookies(),
        eventProps: {
            category: "resource_interest",
            action: "click",
            eventPage: "Favourites",
            label: `${resource.title[0]?.text}`,
            transport: "beacon"
        }
    })

    window.dataLayer.push({
        event: "select_item",
        user_id: getUserIDfromCookies(),
        item_list_name: "resource_favorited",
        items: [
            {
                item_name: resource.title[0]?.text,
                item_category: window.location.pathname,
            }
        ]
    })
    
  }, [])

  const onRemoveFavouriteResource = useCallback(async resource => {
    const fav = favourites.find(f => f.resource.id === resource.id)
    await favouritesApi.removeFavourite(fav.id)
    await fetchFavourites()
  }, [favourites])

  useEffect(() => {
    (async () => {
      await fetchFavourites()
    })()
  }, [])

  const mentalHealthFavs = useMemo(() => {
    return favourites.filter(f => f.isMentalHealth())
  }, [favourites])
  
  const mindfulnessFavs = useMemo(() => {
    return favourites.filter(f => f.isMindfulness())
  }, [favourites])

  const eventFavs = useMemo(() => {
    return favourites.filter(f => f.isEvent())
  }, [favourites])

  const liveStreamFavs = useMemo(() => {
    return favourites.filter(f => f.isFitnessLiveStream())
  }, [favourites])

  const onDemandFavs = useMemo(() => {
    return favourites.filter(f => f.isFitnessOnDemand())
  }, [favourites])

  const onCampusFavs = useMemo(() => {
    return favourites.filter(f => f.isFitnessOnCampus())
  }, [favourites])

  const clubsFavs = useMemo(() => {
    return favourites.filter(f => f.isClub())
  }, [favourites])

  const clubResourceFavs = useMemo(() => {
    return favourites.filter(f => f.isClubResource())
  }, [favourites])

  const tabs = [
    {
      title: t("event"),
      favourites: eventFavs
    },
    {
      title: t("mentalHealth"),
      favourites: mentalHealthFavs
    },
    {
      title: t("mindfulnessResource"),
      favourites: mindfulnessFavs
    },
    // {
    //   title: t("liveStreamClass"),
    //   favourites: liveStreamFavs
    // },
    // {
    //   title: t("onDemandClass"),
    //   favourites: onDemandFavs
    // },
    // {
    //   title: t("onCampusClass"),
    //   favourites: onCampusFavs
    // },
    // {
    //   title: t("clubs"),
    //   favourites: clubsFavs
    // },
    // {
    //   title: t("amsResources"),
    //   favourites: clubResourceFavs
    // }
  ]

  return (
    <>

      <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
      </Helmet>

      <div className="favourites">
        <div className="favourites__title heading-secondary">{t("myFavourites")}</div>

        <Tabs defaultActiveKey="1">
          {
            tabs.map((tab, idx) => {
              return (
                <TabPane tab={tab.title} key={idx}>
                  <div className="list-page">
                    <div className="list-page__content">
                      <div className="list-page__list">
                        {tab.favourites.length === 0 &&
                            <NoResources mode = "favs"/>
                        }
                        {tab.favourites.map((favourite, index) => (
                          <Resource
                            resource={favourite.resource}
                            isFavourite={true}
                            key={index}
                            onAddFavourite={onAddFavouriteResource}
                            onRemoveFavourite={onRemoveFavouriteResource}
                            showConfirmRemoveDialog
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </TabPane>
              )
            })
          }
        </Tabs>
      </div>
    </>
  );
};

export default withLayout()(Favourites);