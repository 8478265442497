import { FilterGroup } from "./group";
import { BaseClientV2 } from "./v2client";

class FilterGroupV2 extends BaseClientV2 {
    BASE_URL = "/filter-group"

    async getSearchable(): Promise<FilterGroup[]> {
        const { data } = await super.get(`${this.BASE_URL}/searchable`);
        return data?.filterGroups;
    }

    async getSearchableByParentGroup(parentId: string): Promise<FilterGroup[]> {
        const { data } = await super.get(`${this.BASE_URL}/${parentId}/searchable`);
        return data?.filterGroups;
    }
}

export const filterGroupV2Api = new FilterGroupV2();