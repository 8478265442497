import React, { useContext, useState, useEffect, useCallback, useMemo } from "react";

import get from "lodash/get"

import Filters from "components/Filters";
import Resource from "components/Items/Resource";
import NoResources from "components/Items/NoResources";
import CustomDrawer from "components/CustomDrawer/CustomDrawer";
import Pagination from "components/Pagination";

import LocaleContext from "contexts/locale";

import LoadingWrapper from "wrappers/loading";

import { getUserIDfromCookies } from "util/getUserIdFromCookie";

import { resourcesApi, favouritesApi } from "api";

const ResourcesList = ({ resources, tags, appliedFilters, updateAppliedFilters, total, loadingResources, currentPage, onPageChange, renderTitle, showCalendarLink, setPage }) => {

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [favourites, setFavourites] = useState([]);
  
  const { t } = useContext(LocaleContext);
  
  useEffect(() => {
    (async () => {
      await fetchFavourites()
    })()
  }, [])

  const handleFilter = (newId, key) => {
    
    let newAppliedFilters = { ...appliedFilters };
    let filterArr = get(newAppliedFilters, key, [])
    let filterSet = new Set(filterArr)

    if (filterSet.has(newId)) {
      filterSet.delete(newId)
    } else {
      filterSet.add(newId)
    }
    
    newAppliedFilters[key] = Array.from(filterSet)

    // a better way to set user back to page one when the filters change?
    setPage(1)
    updateAppliedFilters(newAppliedFilters);
  };

  const fetchFavourites = async () => {
    try {
      const result = await favouritesApi.getAllFavourites({}, { fetchAll: true })
      setFavourites(result.data)
    } catch(e) {}
  }

  const onAddFavouriteResource = useCallback(async resource => {
    await favouritesApi.addFavourite(resource.id)
    await fetchFavourites()

    window.dataLayer.push({
        event: "resource_favourited",
        user_id: getUserIDfromCookies(),
        eventProps: {
            category: "resource_interest",
            action: "click",
            eventPage: "BrowseAll",
            label: `${resource.title[0]?.text}`,
            transport: "beacon"
        }
    })

    window.dataLayer.push({
        event: "select_item",
        user_id: getUserIDfromCookies(),
        item_list_name: "resource_favorited",
        items: [
            {
                item_name: resource.title[0]?.text,
                item_category: window.location.pathname,
            }
        ]
    })
    
  }, [])

  const onRemoveFavouriteResource = useCallback(async resource => {
    const fav = favourites.find(f => f.resource.id === resource.id)
    await favouritesApi.removeFavourite(fav.id)
    await fetchFavourites()
  }, [favourites])

  const favouriteIds = useMemo(() => {
    return favourites.map(fav => {
      return fav.resourceId
    })
  }, [favourites])

  return (
    <LoadingWrapper>
      <div className="list-page list-page--filters">

        {/* Mobile drawer */}
        <CustomDrawer open={drawerOpen} handleClose={() => setDrawerOpen(false)}>
          <Filters
          inDrawer
          appliedFilters={appliedFilters}
          updateAppliedFilters={updateAppliedFilters}
          handleFilter={handleFilter}
          tags={tags} />
        </CustomDrawer>

        <div className="list-page__filters">
          <Filters
            appliedFilters={appliedFilters}
            updateAppliedFilters={updateAppliedFilters}
            handleFilter={handleFilter}
            tags={tags}
          />
        </div>

        <div className="list-page__content">
          {renderTitle()}
          <button className="list-page__filter-button button--blue" onClick={() => setDrawerOpen(true)}>
            {t("filters")}
          </button>

          <div className="list-page__list">
            <LoadingWrapper loading={loadingResources} useSpinner>
              <>
              {resources.length === 0 &&
                    <NoResources mode = "normal" />
                }
                {resources.map((resource) =>
                  (
                    <Resource
                      resource={resource}
                      key={resource.id}
                      isFavourite={favouriteIds.includes(resource.id)}
                      onAddFavourite={onAddFavouriteResource}
                      onRemoveFavourite={onRemoveFavouriteResource}
                      displayTags={resource.tags}
                      // showCalendarLink={showCalendarLink}
                      // score={resource.score}
                    />
                  )
                )}
              </>
            </LoadingWrapper>
          </div>
          {!loadingResources &&
            <div className="list-page__pagination">
              <Pagination
                current={currentPage}
                onChange={onPageChange}
                pageSize={resourcesApi.DEFAULT_PAGE_SIZE}
                total={total}
                showSizeChanger={false}
              />
            </div>
          }
        </div>
      </div>
    </LoadingWrapper>
  );
};

ResourcesList.defaultValues = {
  favourites: []
}

export default ResourcesList;
