import { motion } from "framer-motion"

import "./index.scss";

const Backdrop = ({ children, onClick, exitDuration}) => {
    return (
        <motion.div
        className="backdrop"
        onClick={onClick}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: {duration: exitDuration}}}
        >
            {children}
        </motion.div>
    )
}

export default Backdrop