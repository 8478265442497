import { get } from 'lodash';
import BaseModel from './base-model';
import Resource from './resource';

class Recommendation extends Resource {
  constructor(apiRecommendation) {

    // Because Recommendations are displayed in roughly the same way as Resources,
    // We just extend the resource and then add some extra properties,
    // Some of which have actually been borrowed from favourites
    super(apiRecommendation.resource)
    this.id = apiRecommendation.id
    this.resourceId = get(this.resource, 'id')
    this.resourceType = get(this.resource, 'type')
    this.score = apiRecommendation.score
    this.hits = apiRecommendation.hits
  }

  static fromArray(arrayOfRecommendations = []) {
    return arrayOfRecommendations.map(recommendation => {
      // They must not be getting passed in here?
      const rec = new Recommendation(recommendation)
      // it's in the constructor though
      return rec
    })
  }

  isEvent() {
    return this.resourceType === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.resourceType === Resource.TYPES.QUICK_LINK;
  }

  isPhysicalHealth() {
    return this.resourceType === Resource.TYPES.PHYSICAL_HEALTH;
  }

  isMentalHealth() {
    return this.resourceType === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.resourceType === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.resourceType === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.resourceType === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }

  isMindfulness() {
    return this.resourceType === Resource.TYPES.MINDFULNESS;
  }

  isFitnessLiveStream() {
    return this.resourceType === Resource.TYPES.FITNESS_LIVE_STREAM;
  }

  isFitnessOnDemand() {
    return this.resourceType === Resource.TYPES.FITNESS_ON_DEMAND;
  }
  
  isFitnessOnCampus() {
    return this.resourceType === Resource.TYPES.FITNESS_ON_CAMPUS;
  }

  isOfType(type) {
    return this.resourceType === type;
  }
}

export default Recommendation;