import React, { useContext, useState, useEffect } from "react";

import LocaleContext from "contexts/locale";

import ResourcesList from "pages/_common/ResourcesList";

import { resourcesApi } from "api";
import { withLayout } from "hoc/withLayout";

const OnDemand = () => {
  const [loadingResources, setLoadingResources] = useState(true)
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([])
  const [appliedFilters, setAppliedFilters] = useState({});
  const [page, setPage] = useState(1);
  const [pages,setPages] = useState(0)
  
  const { t } = useContext(LocaleContext);

  useEffect(() => {
    async function fetchData() {
      setLoadingResources(true);

      try {
        // We are subtracting 1 from the page because the pagination component starts at index 1
        const result = await resourcesApi.getOnDemandResources({...appliedFilters, page: page - 1})
  
        setResources(result.data)
        setPages(result.total)
      } catch(e) {}

      setLoadingResources(false);
    }
    fetchData();
  }, [appliedFilters, page, pages]);

  useEffect(() => {
    (async () => {
      try {
        const result = await resourcesApi.getOnDemandResourceTags()
        setTags(result.data)
      } catch(e) {}
    })()
  }, [])

  const onPageChange = (page) => {
    setPage(page)
  }


  return (
    <ResourcesList
      appliedFilters={appliedFilters}
      onPageChange={onPageChange}
      tags={tags}
      loadingResources={loadingResources}
      total={pages}
      updateAppliedFilters={setAppliedFilters}
      currentPage={page}
      resources={resources}
      renderTitle={() => {
        return (
          <>
            <div className="list-page__title">
              {t("browseAllOnDemandsHeading")}
            </div>
            <div className="list-page__title">{t("onDemandClasses")}</div>
            <div className="list-page__heading">{t("filterByLang")}</div>
          </>
        )
      }}
    />
  )
};

export default withLayout()(OnDemand);
