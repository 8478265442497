//Image
import React, { useContext, useState } from "react";
import AuthContext from "contexts/auth";
import { studentApi } from "api";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"

import { getUserIDfromCookies } from "util/getUserIdFromCookie";


import "./index.scss";

const WelcomeTOS = ({org}) => {

  const pageTitle = GA4Pageview("Terms of Service")

    const [checked, setChecked] = useState(false);
    const { setAcceptedTermsOfService } = useContext(AuthContext)

    const tos_url = `https://tos-app-documents.s3.ca-central-1.amazonaws.com/${org.appId}.pdf`
    console.log(org)

    const handleClick = async() => {
        if (checked){

            window.dataLayer.push({
                event: "event",
            user_id: getUserIDfromCookies(),
                eventProps: {
                    category: "TOS",
                    action: "click",
                    label: `User accepted their Org's TOS`,
                    transport: "beacon"
                }
            })

            await studentApi.updateProfile({"state.acceptedTermsOfService": true}) // update DB
            setAcceptedTermsOfService(true) // update State, causing us to leave the page
        } else {
            alert("Please accept the terms of service if you would like to continue to the site.")
        }
    };

    const handleCheckboxChange = () => {
        setChecked(!checked)
    }

  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
      </Helmet>
      
      <div className="TOS-page">
        <h1 className="TOS-desktop"> Welcome to Compass! Please read our Terms of Service: </h1>
        <div className="TOS-mobile">
          <h1> Welcome to Compass!</h1>
          <h1>
              <a href={tos_url}> Please tap here to read our Terms of Service </a>
          </h1>
        </div>
        {/* <embed src={tos_url} width="800px" height="300px" /> */}

        {/* <a href={tos_url}> asdf</a> */}
        <embed
          src={tos_url}
          type="application/pdf"
          frameBorder="0"
          scrolling="auto"
          height="100%"
          width="100%"
        ></embed>
        {/* <iframe src={tos_url} width="100%" height="500px" /> */}
      
        <div className = "acceptance-indication">
          <input type="checkbox" id="vehicle1" name="vehicle1" className = "checkbox" value="Bike" onChange = {handleCheckboxChange} ></input>
          <label> I understand and agree to the Terms of Service above </label>
        </div>

        <button className = "continue-button"
        onClick={handleClick}>
          <h2> Continue </h2>
        </button>
      </div>
    </>
  );
};

export default WelcomeTOS;