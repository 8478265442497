import React, { useCallback, useState } from 'react';
import get from 'lodash/get';
import Cookies from "js-cookie";

import translations from '../locales';


const LocaleContext = React.createContext({
  locale: '',
  locales: [],
  translate: (arg) => '',
  t: (arg) => '',
})

const LOCALE_COKIE_NAME = "compass_locale"
const DEFAULT_LOCALE = "en-US"

export const LocaleContextProvider = ({ children }) => {
  const [localeState, setLocaleState] = useState({ locale: Cookies.get(LOCALE_COKIE_NAME) || 'en-US' })
  
  const locales = Object.keys(translations)

  const setLocale = locale => {
    if (locales.includes(locale)) {
      Cookies.set(LOCALE_COKIE_NAME, locale)
      setLocaleState({ locale })
    }
  }

  const translate = useCallback((key, replacements = {}) => {
    const replacementKeys = Object.keys(replacements);
    let translatedString = get(translations, `${localeState.locale}.${key}`, '');

    replacementKeys.forEach(key => {
      const replacement = replacements[key] || replacements[DEFAULT_LOCALE]
      translatedString = translatedString.replace(`$${key}`, replacement)
    })

    return translatedString;
  }, [localeState.locale])
  
  localeState.locales = locales;
  localeState.t = translate;
  localeState.setLocale = setLocale;

  return (
    <>
      <LocaleContext.Provider
        value={localeState}
      >
        {children}
      </LocaleContext.Provider>
    </>
  )
}

export default LocaleContext;