import React, { useState } from "react";

import '../staffbanner.scss'

const Hotjar = ({ user }) => {

    const [debuggerName, setDebuggerName]= useState(window.localStorage.debuggerName)

    const handleChange = (event) => {
        window.localStorage.setItem("debuggerName", event.target.value)
        setDebuggerName(event.target.value)
        window.dataLayer.push({debugger: event.target.value})
    }

    const hn = window.location.hostname
    let envName = 'unknown'
    let envColor = 'grey'
    let docsColor = 'grey'

    if (hn.includes(".checkcompass.ca")){
        envName = "Production ⚙️"
        envColor = "#2f103c"
        docsColor = "#b15ed5"
    }

    if (hn.includes(".compassnext.ca")){
        envName = "Staging 🎭"
        envColor = "orange"
    }

    if (hn.includes(".compassdev.ca") || hn.includes("localhost")){
        envName = "Local! 🏠"
        envColor = "black"
    }

    console.log(`environment detected: ${envName}`)

    const startHotjar = () => {
        var s = document.createElement("script")
        s.type = "text/javascript"
        s.innerHTML = `
        (function (h, o, t, j, a, r) {
            h.hj =
                h.hj ||
                function () {
                (h.hj.q = h.hj.q || []).push(arguments);
                };
            h._hjSettings = { hjid: 1976609, hjsv: 6 };
            a = o.getElementsByTagName("head")[0];
            r = o.createElement("script");
            r.async = 1;
            r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
            a.appendChild(r);
            })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");
        `
        document.head.appendChild(s);
        console.log("Attempted to add hotjar script to site")
    }
    
    function isMyScriptLoaded(url) {
        if (!url) url = "http://xxx.co.uk/xxx/script.js";
        var scripts = document.getElementsByTagName('script');
        for (var i = scripts.length; i--;) {
            if (scripts[i].src == url) return true;
        }
        return false;
    }
    
    const hotjarAlreadyLoaded = isMyScriptLoaded("https://static.hotjar.com/c/hotjar-1976609.js?sv=6")
    if (!hotjarAlreadyLoaded && !user?.state?.staffAccount){
        console.log("no hotjar script found - starting now")
        startHotjar()
    } else if (user.state.staffAccount){
        console.log("This is a staff account, so we're not going to load hotjar")
    } else {
        console.log("hotjar script already running")
    }

    const hotjarScriptFound = isMyScriptLoaded("https://static.hotjar.com/c/hotjar-1976609.js?sv=6")
    console.log(`Hotscript is present: ${hotjarScriptFound} ${hotjarScriptFound === false ? "❌": "✅"}`)


  return (
    <>

    {user && user.state && user.state.staffAccount &&
        <div style={{background: envColor}} className="staff-account-banner">

            <span style={{textAlign: "center"}}> Testing Account</span>
            <a target="_blank" rel="noreferrer" href='https://www.notion.so/teamco/Internal-Traffic-Blocking-for-Analytics-eca9301f6f1343a2b694c7cb54b210c5' style={{textAlign: "center", color: docsColor}}> \\ Staff Account Documentation</a>
            <div className = "debugger-name">
                <p>Debugger name:</p><input value = {debuggerName} name="debugger" onChange={handleChange}></input>
                <span style={{textAlign: "right", float: "right", paddingRight: "30px"}}> {envName} </span>
            </div>

        </div>
    }
    </>
  );
};

export default Hotjar;
