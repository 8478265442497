//Image
import React, { useContext } from "react";
import AuthContext from "contexts/auth";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"

import { studentApi } from "api";
import "./index.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { faLinkedinIn, faInstagram} from "@fortawesome/free-brands-svg-icons"

import { getUserIDfromCookies } from "util/getUserIdFromCookie";


const Welcome = () => {

    const pageTitle = GA4Pageview("Welcome")

    const { setHasBeenWelcomed } = useContext(AuthContext)
    const welcomeToLocation = window.location.href.includes('mywellness') ? "myWellness" : "Compass"

    const handleClick = async() => {

        window.dataLayer.push({
            event: "user_welcomed",
            user_id: getUserIDfromCookies(),
            eventProps: {
                category: "conversions",
                action: "click",
                label: `User has been welcomed`,
                transport: "beacon"
            }
        })

        await studentApi.updateProfile({"state.hasBeenWelcomed": true}) // update DB
        setHasBeenWelcomed(true) // update State, causing us to leave the page
    };

    return (
        <>

          <Helmet>
              <meta charSet="utf-8" />
              <title>Welcome!</title>
          </Helmet>

        <div className="welcome-page">
          <h1> Welcome to {welcomeToLocation}! </h1>

          <p className = "welcome-subheader">
              We’re SO happy you are here 🤗  You can think of Compass as your dashboard for mental, physical, and social wellbeing. This platform was created by students, for students. We are constantly evolving, just like you. 😋 
              <br></br><br></br>
              What you can expect:
          </p>

          <div className = "welcome-section">
              <div className = "welcome-section-emoji"><span>🔍</span></div>
              <div className = "welcome-section-text">
                  <h2>Explore Community, Mental Health, and Physical Health Resources</h2>
                  <p>Resources include services, centres, group support, self-guided tools, events and more…</p>
              </div>
          </div>

          <div className = "welcome-section">
              <div className = "welcome-section-emoji"><span>🎯</span></div>
              <div className = "welcome-section-text">
                  <h2>Get recommended resources just for you</h2>
                  <p>based on your unique needs and wants</p>
              </div>
          </div>

          <div className = "welcome-section">
              <div className = "welcome-section-emoji"><span>📍</span></div>
                  <div className = "welcome-section-text">
                  <h2>Tune in to what’s happening in your community</h2>
                  <p>online, on-campus and off-campus</p>
              </div>
          </div>

          <div className = "welcome-contact">
              <span> Hit us up if you have any questions, feedback, or ideas: </span>
              <span className = "welcome-icon-set">
                  <a href = "https://ca.linkedin.com/company/checkcompass">
                      <FontAwesomeIcon className = "welcome-icon" icon = {faLinkedinIn}></FontAwesomeIcon>
                  </a>
                  <a href = "https://www.instagram.com/accounts/login/?next=/checkcompass/">
                      <FontAwesomeIcon className = "welcome-icon" icon = {faInstagram}></FontAwesomeIcon>
                  </a>
                  <a href = "info@checkcompass.ca">
                      <FontAwesomeIcon className = "welcome-icon welcome-icon-farright" icon = {faEnvelope}></FontAwesomeIcon>
                  </a>
              </span>
          </div>

          <p> Your privacy is our top priority. We are committed to the confidentiality and security of your data. <a href="https://checkcompass.ca/privacypolicy">Check out how we honour that commitment here.</a></p>

          <button className = "continue-button" onClick={handleClick}>
              <h2> Continue your Mental Health Journey </h2>
          </button>
        </div>
      </>
    );
};

export default Welcome;