import memoize from "lodash/memoize";

import Resource from "./resource";
import BaseModel from "./base-model";

class ResourceTag extends BaseModel {
  constructor(apiTag) {
    super();
    this.id = apiTag.id;
    this.resourceType = apiTag.resourceType;
    this.group = apiTag.group;
    this.title = apiTag.title;
    this.groupObj = this._localeObjArrToObj(apiTag.group)
    this.titleObj = this._localeObjArrToObj(apiTag.title)
  }

  getLocaleTitle = memoize(locale => {
    return this._extract_locale_text_from_obj(this.titleObj, locale)
  })

  isEvent() {
    return this.resourceType === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.resourceType === Resource.TYPES.QUICK_LINK;
  }

  isMentalHealth() {
    return this.resourceType === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.resourceType === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.resourceType === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.resourceType === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }
}

export default ResourceTag;