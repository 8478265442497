import React, { useContext, useState, useEffect } from "react";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"

import moment from "moment";

import { resourcesApi } from 'api';

import LocaleContext from "contexts/locale";

import WeeklyClasses from "pages/_common/WeeklyClasses";
import { withLayout } from "hoc/withLayout";

const LiveStream = () => {
  const pageTitle = GA4Pageview("LiveStreams")

  const [currMonday, setCurrMonday] = useState(moment().startOf("isoWeek"));
  const [resources, setResources] = useState([]);
  const [loadingResources, setLoadingResources] = useState(false);

  const { t } = useContext(LocaleContext);

  useEffect(() => {
    async function fetchData() {
      setLoadingResources(true)

      if (currMonday) {
        try {
          const start = currMonday.valueOf();
          const end = currMonday.clone().add(7, 'days').valueOf()
          const result = await resourcesApi.getLiveStreamResources({ start, end })
          setResources(result.data)
        } catch(e) {}
      }

      setLoadingResources(false)
    }
    fetchData();
  }, [currMonday]);

  return (
    <>

        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
        </Helmet>

        <WeeklyClasses
        renderTitle={() => {
            return (
            <div className="class-page__title">{t("liveStreamTitle")}</div>
            )
        }}
        resources={resources}
        loadingResources={loadingResources}
        onMondayChange={setCurrMonday}
        currentMonday={currMonday}
        />
    </>
  )
};

export default withLayout()(LiveStream);
