import memoize from "lodash/memoize";

import BaseModel from "./base-model";

export default class Resource extends BaseModel {
  static TYPES = {
    EVENT: 'EVENT',
    QUICK_LINK: 'QUICK_LINK',
    MENTAL_HEALTH: 'MENTAL_HEALTH',
    CLUB: 'CLUB',
    CLUB_RESOURCE: 'CLUB_RESOURCE',
    EMERGENCY_SUPPORT_INFO: 'EMERGENCY_SUPPORT_INFO',
    FITNESS_LIVE_STREAM: 'FITNESS_LIVE_STREAM',
    FITNESS_ON_DEMAND: 'FITNESS_ON_DEMAND',
    FITNESS_ON_CAMPUS: 'FITNESS_ON_CAMPUS',
    MINDFULNESS: 'MINDFULNESS',
    MESSAGE_OF_THE_DAY: 'MOTD'
  }

  constructor(apiResource) {
    super()
    this.id = apiResource.id;
    this.favourite = apiResource.favourite;
    this.link = apiResource.url;
    this.title = apiResource.title;
    this.instagram = apiResource.instagram;
    this.facebook = apiResource.facebook;
    this.twitter = apiResource.twitter;
    this.dicord = apiResource.discord;
    this.type = apiResource.type;
    this.tags = apiResource.tags;
    this.details = apiResource.details;
    this.location = apiResource.location
    this.startTime = apiResource.startOfFirstDateTimeUTC;
    this.endTime = apiResource.endOfLastDateTimeUTC;
    this.imageUrl = apiResource.imageUrl;
    this.favsCount = apiResource.favsCount;
  }

  static fromArray(arrayOfResources = []) {
    return arrayOfResources.map(resource => new Resource(resource))
  }

  getLocaleDetail = memoize(locale => {
    return this._extract_locale_text(this.details, locale)
  })

  getLocaleLocation = memoize(locale => {
    return this._extract_locale_text(this.location, locale)
  })

  getLocaleTitle = memoize(locale => {
    return this._extract_locale_text(this.title, locale)
  })

  hasDiscord() {
    return !!this.dicord;
  }

  hasFacbook() {
    return !!this.facebook;
  }

  hasInstagram() {
    return !!this.instagram;
  }

  hasTwitter() {
    return !!this.twitter;
  }

  isEvent() {
    return this.type === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.type === Resource.TYPES.QUICK_LINK;
  }

  isMentalHealth() {
    return this.type === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.type === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.type === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.type === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }
}