import React from "react";

const StaffAccountRecord= ({ user }) => {
  
  if (user && user.state && user.state.staffAccount){
    console.log("This is a staff account - pushing the staffAccount user parameter to GA4")

    window.dataLayer.push({
        event: "userData",
        staffAccount: 'true',
    })

    window.localStorage.setItem('staffAccountRecord', 'true')
    window.localStorage.setItem('staffAccountLive', 'true')

  } else {
    window.localStorage.setItem('staffAccountLive', '')
    console.log("This is not a staff account and GA4 will not be prevented from loading in the future, unless a localStorage record persists.")
    console.log("If you have to, consider running non-staff accounts in another browser than staff accounts to prevent some GA4 recording on first page load, as well as confusion with localStorage.")
  }

  return null
};

export default StaffAccountRecord;
