const locales = {
  "en-US": "English",
  "fr-CA": "French",

  hi: 'Hi',
  // Nav Bar
  mentalHealth: "Mental Health",
  myCommunity: "My Community",
  myMentalHealth: "Mental Health",
  myPhysicalHealth: "My Physical Health",
  myClubs: "AMS-Clubs",
  myFavourites: "My Favourites",
  emergencySupport: "Emergency Support",
  profile: "Profile",
  favourites: "My Favourites",
  logout: "Logout",
  // My Community Page
  happeningToday: "What's Happening Today",
  quickLinks: "Quick Links",
  noEventsToday: "No events today",
  myCampusCalendar: "My Campus Calendar",
  // My Mental Health Page
  myRecommendedResources: "My Recommended Resources",
  recommendedHeading: "Unsure where to start? Take a quick assessment to find resources right for you",
  surveyLink: "https://intakeq.com/new/wmASSL",
  start: "Start",
  browseResources: "Browse All Resources",
  browseHeading:
    "View online, on campus, and off campus mental health resources. Filtered for your needs and preferences",
  viewAll: "View All",
  mindfulnessResources: "Mindfulness Library",
  mindfulnessHeading: "Simple and actionable techniques, meditations, and information for mental wellness",
  uploadWeekly: "We upload new resources weekly.",
  // My Physical Health Path
  fitnessHeading: "Choose how you feel like moving",
  liveStreamClasses: "Livestream Classes",
  liveStreamTitle: "Livestreams Classes this Week",
  liveStreamHeading: "Workout real-time with an instructor, wherever you are",
  onDemandClasses: "On-Demand Classes",
  onDemandHeading: "Explore pre-recorded workout classes from boxing, yoga, pilates and more",
  onCampusClasses: "On-Campus Classes",
  onCampusHeading: "Check out what fitness classes are available on campus",
  gymTime: "Gym Time",
  gymTimeHeading: "Book your gym time here",
  gymLink: "https://geegeereg.uottawa.ca/geegeereg/Activities/ActivitiesDetails.asp?aid=316#",
  gymLinkQueens: "https://getactive.gogaelsgo.com/Program/GetProducts?classification=b2e9f15b-dbaa-4f55-8bb3-6c1ca1c00e32",
  intakeQID: "wmASSL",

  // Navflow
  intakeQID: "wmASSL",
  retakeSurvey: "Retake Survey!",
  takeSurvey: "Take Survey",
  viewRecommendationsButton: "Find Recommendations",
  relevanceScore: "Relevance",
  finishedSurveyTitle: "We used your answers to find these resources specifically for you",
  hitsExplanation1: "Your answers and their respective weights multiply to a relevance score of ",
  hitsExplanation2: " for this resource: ",
  hitsExplanation3: "You're seeing this because it shares these tags with your survey",

  //AMS-Clubs Path
  browseAllClubs: "Browse All Clubs",
  browseAllClubsHeading: "Browse All Clubs",
  browseAllClubsDescription: "Looking to get involved? Browse all clubs and filter based on what you’re looking for!",
  browseAllClubsResources: "Browse All Clubs Resources",
  browseAllClubsResourcesDescription: "Looking for clubs resources like grants, room bookings, clubs manual and more? Browse all resources here!",
  governance: "Governance",

  deselectAll: "Deselect All",
  filters: "Filters",
  filterByLang: "The following content varies by language",
  tags: "Tags",
  types: "Types",
  methods: "Methods",
  groups: "Groups",
  faculty: "Faculty",
  language: "Language",
  english: "English",
  french: "French",
  bilingual: "Bilingual",
  location: "Location",
  onCampusClass: "On-Campus Class",
  onDemandClass: "On-Demand Class",
  liveStreamClass: "Livestream Class",
  classPast: "Class has past",
  mindfulnessResource: "Mindfulness Resource",
  resource: "Resource",
  event: "Event",
  eventPast: "Event has past",
  //Miscellaneous
  readMore: "Read More",
  readLess: "Read Less",
  viewClass: "View Class",
  addToCalendar: "Add To Google Calendar",
  sendToFriend: "Send To Friend",
  addFavourite: "Add Favourite",
  removeFavourite: "Remove Favourite",
  confirmRemoveFavourite: "Are you sure you would like to remove favourite?",
  yes: "Yes",
  no: "No",
  learnMore: "Learn More",
  hostLink: "Host Link",
  clubs: "AMS-Clubs",
  amsResources: "AMS-Resources",
  nextOn: "Next on $dateTimeText, see more dates on calendar",
  fitnessFooter:
    "Every body and every mind requires varying levels of physical movement - exercise should be practiced in moderation.\nWe encourage you to listen to your physical and psychological needs to determine the appropriate type and amount of exercise for you.",
  mentalHealthFooter:
    "These are tools to support general mental wellbeing, but are not a substitute for professional mental health services.\nPlease refer to the emergency support button for immediate and acute mental health support or visit your physician.",
  contactUsAt: "Contact us at",
  checkPrivacyPolicy: "Check out our Privacy Policy",
  register: "Register",
  pleaseRegister: "Please Register",
  name: "Name",
  email: "Email",
  yearOfStudy: "Year of Study",
  password: "Password",
  conPassword: "Confirm Password",
  alreadyAccount: "Already have an account?",
  login: "Login",
  backToLogin: "Back to Login Page?",
  send: "Send",
  notYetSignedUp: "Not yet signed up?",
  enter: "Enter",
  enterEmail: "Enter Your University Email",
  enterPassword: "Enter Your Password",
  preferredName: "Preferred Name",
  yourPreferredName: "Your Preferred Name",
  forgotPassword: "Forgot Password",
  emailNotFound: "Could not find email",
  yourHolisticPlatform: "Created by students, for students. Your one stop shop for finding mental health resources, on campus, off campus and online based on your unique needs, wants and identity.",
  welcomeBack: "Welcome",

  prev: "Prev",
  next: "Next",
  today: "Today",
  week: "Week",
  month: "Month",
  fitness: "Fitness",
  day: "Day",


  university: "University",
  save: "Save",
  saved: "Saved",
  saving: "Saving",

  selectYear: "Select Year",
  firstYear: "First Year",
  secondYear: "Second Year",
  thirdYear: "Third Year",
  fourthYear: "Fourth Year",
  fifthYear: "Fifth Year +",

  copyToClipboard: "Copied to Clipboard",
  checkOutTheLink: "Check out the link here",

  waitForRecommendations: `
  Thank you for completing the assessment form 💙.
  <br /><br />
  As the <b>next step</b>, you will receive an email within the next 48 hours from our team, with a list of recommended resources based on your unique needs, wants and identity.
  <br /><br />
  If there is anything else you’d like to add to your form or share with our team, please email <a href="mailto:info@checkcompass.ca"><u>info@checkcompass.ca</u></a>.
  <br /><br />
  If you require emergency support, please go to the red emergency support button at the top of this page.
  <br /><br />
  Here with you,
  <br />
  The Compass Team
  `
}

export default locales;