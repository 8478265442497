import React, { useContext, useState, useEffect } from "react";
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet"
import useDebounce from "util/useDebounce";

import { Input } from 'antd';

import LocaleContext from "contexts/locale";

import ResourcesList from "pages/_common/ResourcesList";

import { resourcesApi } from "api";
import { withLayout } from "hoc/withLayout";

const Mindfulness = () => {

  const pageTitle = GA4Pageview("Mindfulness")

  // ReactGA.pageview(window.location.pathname + window.location.search);
  const [loadingResources, setLoadingResources] = useState(true)
  const [resources, setResources] = useState([]);
  const [tags, setTags] = useState([])
  const [appliedFilters, setAppliedFilters] = useState({});

  const [search, setSearch] = useState('')
  const debouncedSearch = useDebounce(search, 500)


  const [page, setPage] = useState(1);
  const [pages,setPages] = useState(0)
  
  const { t } = useContext(LocaleContext);

  useEffect(() => {
    async function fetchData() {
      setLoadingResources(true);

      try {
        // We are subtracting 1 from the page because the pagination component starts at index 1
        const result = await resourcesApi.getMindfulnessResources(
          {...appliedFilters,
            page: page - 1,
            search: debouncedSearch,
          }
        )
  
        setResources(result.data);
        setPages(result.total)
      } catch(e) {}

      setLoadingResources(false);
    }
    fetchData();
  }, [appliedFilters, page, pages, debouncedSearch]);

  useEffect(() => {
    (async () => {
      try {
        const result = await resourcesApi.getMindfulnessResourceTags()
        setTags(result.data)
      } catch(e) {}
    })()
  }, [])

  const onPageChange = (page) => {
    setPage(page)
  }


  return (
    <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>{pageTitle}</title>
        </Helmet>

        <Input
        className = "searchbar"
        placeholder="search"
        value = {search}
        onChange={e => setSearch(e.target.value)} />

        <ResourcesList
        appliedFilters={appliedFilters}
        onPageChange={onPageChange}
        tags={tags}
        loadingResources={loadingResources}
        total={pages}
        updateAppliedFilters={setAppliedFilters}
        currentPage={page}
        resources={resources}
        showCalendarLink
        setPage={setPage}
        renderTitle={() => {
            return (
            <>
                <div className="list-page__title">
                        {t("mindfulnessResources")}
                    </div>
                    <div className="list-page__heading">{`${t("uploadWeekly")} ${t("filterByLang")}`}</div>
            </>
            )
        }}
        />
    </>
  )
};

export default withLayout()(Mindfulness);
