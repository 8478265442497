import { AnimatePresence } from "framer-motion";
import { useState, useEffect, useContext} from "react";
import Modal from "../EngagementModalComponents/Modal/index"
import cookies from "js-cookie"
import { BASE_URL } from "util/config";
import LocaleContext from "contexts/locale";
import { recommendationsApi } from "../../api/index";
import { getUserIDfromCookies } from "util/getUserIdFromCookie";

const BrowserNotifications = () => {

  // PROD
  const PERMISSION_MODAL_WAIT_SECONDS = 12
  const NOTIFICATIONS_INTERVAL_MINUTES = 18

  // TESTING
//   const PERMISSION_MODAL_WAIT_SECONDS = 1

  const { locale } = useContext(LocaleContext);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false)
  const [recommendations, setRecommendations] = useState([]);
  const [loadingRecommendations, setLoadingRecommendations] = useState(true)

  let surveyURL = `${BASE_URL}/survey/start?locale=${locale}`

  const messages = {
    "ba_": {
      title: "Looking for mental health support? 🧐",
      body: "Click here to explore what's available to you!",
      url: window.location.origin + "/#/mental-health/all-resource"
    },
    "ts_": {
      title: 'Want individualized mental health support? 🧠',
      body: "Take our survey by clicking here and we'll recommend helpful mental health resources.",
      url: surveyURL
    },
    "vr_": {
      title: "You have mental health recommendations waiting for you! 📚",
      body: "Check them out by clicking here",
      url: window.location.origin + "/#/mental-health/recommendations"
    }
}

  if (cookies.get("bns") === undefined){
    cookies.set("bns", "_", {path: "/", expires: 7})
  }

  async function fetchRecs() {

    try {
      // We are subtracting 1 from the page because the pagination component starts at index 1
      const result = await recommendationsApi.getAllRecommendations({})
      const resultMapped = []
      for (const key in result.data){
          // NavFlow list component takes a list of resource, not recommendations
          // So we stash the data we need in the resource itself
          // result.data[key].resource.score = result.data[key].score
          // result.data[key].resource.hits = result.data[key].hits
          resultMapped.push(result.data[key])
      }

      if (resultMapped.length > 0){ // check to see if there are recommendations before queueing this as a notification
          const bns = cookies.get("bns")
          if (!bns.includes("_vr_")){
            cookies.set("bns", bns.replace("_vr_", "_"))
          }
      }
      
      messages["vr1_"] = {
        title: `Check out ${resultMapped[0]?.title[0]?.text}?`,
        body: `It might be useful. View it by clicking here.`,
        url: window.location.origin + "/#/mental-health/recommendations"
      }
      
    } catch(e){
      console.log(e)
    }
  }

  useEffect(() => {
    (async () => {
        await fetchRecs()
        if (Notification.permission === "granted"){
            const inter = setInterval(() => {
        
              const s = cookies.get("bns")
        
              for (const x in messages){
                const message = messages[x]
            
                if (!s.includes(x)){
                  let notification;
                  notification = new Notification(message.title, {
                    body: message.body,
                    // data: { num: "123" },
                    icon: "favicon.ico",
                    vibrate: true,
                    tag: "compass"
                  })

                  window.dataLayer.push({
                    event: "browser_notification",
                    user_id: getUserIDfromCookies(),
                    notificationProps: {
                        status: "shown",
                        title: message.title,
                        body: message.body,
                        url: message.url
                    }
                  })
        
                  notification.addEventListener("click", async() => {
                    window.open(message.url)
                    clearInterval(inter)
                    notification.close()

                    window.dataLayer.push({
                        event: "browser_notification",
                        user_id: getUserIDfromCookies(),
                        notificationProps: {
                            status: "clicked",
                            title: message.title,
                            body: message.body,
                            url: message.url
                        }
                    })

                  })
        
                  cookies.set("bns", s + x)
                  return // this only works because we are inside of a function, but prevents each item of loop being run together
                         // we could also use break here
        
                }
            
              }
              
              // multiply # of minutes by 60,000, number of milliseconds in a minute
            // }, 1000)
            }, NOTIFICATIONS_INTERVAL_MINUTES * 60000)
          }
    })()
  }, [notificationsEnabled]);

  // Modal
  const [modalOpen, setModalOpen] = useState(false)
  const closeModal = () => setModalOpen(false)
  const openModal = () => setModalOpen(true)

  useEffect(() => {
      (async () => {
        setTimeout(() => {
        if (Notification.permission !== "granted" && localStorage.getItem("notificationPermission") !== "denied"){
          openModal()
        }
      }, (PERMISSION_MODAL_WAIT_SECONDS * 1000))
    })()
  }, [])

  return (
    <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
      {modalOpen && <Modal modalOpen={modalOpen} handleClose={closeModal} setNotificationsEnabled={setNotificationsEnabled}/>}
    </AnimatePresence>
  )

}

export default BrowserNotifications