import React, { useContext, useEffect } from "react";
import sprites from "imgs/sprites.svg";
import { handleCopy } from "util/copyToClipboard";
import { useToggle } from "hooks";
import CustomModal from "components/CustomModal/CustomModal";
import LocaleContext from "contexts/locale";
import { getUserIDfromCookies } from "util/getUserIdFromCookie";
import ReactHtmlParser from "react-html-parser";
import * as DOMPurify from "dompurify";

export const ClubsDetails = ({ title, description, icon, additional_info = []}) => {
  const { t } = useContext(LocaleContext);
  const [fullDesc, toggleFullDesc] = useToggle(false);
  const descLimit = 160;

  description =
    description.length > descLimit
      ? fullDesc
        ? description
        : `${description.substring(0, descLimit)}...`
      : description;

  return (
    <div className="item__details">
      <div className="item__container">
        <div className="item__title_AMS">{title}</div>
        <div className="item__description">
         {description}
         {description.length > descLimit && (
           <button className="item__read-more" onClick={toggleFullDesc}>
             {fullDesc ? t("readLess") : t("readMore")}
           </button>
         )}
        </div>
      </div>
      {additional_info.map((note, index) => {
        return (
          note && (
            <div className="item__note" key={index}>
              {note}
            </div>
          )
        );
      })}
    </div>
  );
};

export const ClubsFavourite = ({ isFavourite, handleFavourite }) => {
  const { t } = useContext(LocaleContext);

  return (
    <div className="item__option" onClick={handleFavourite}>
      <svg className={`item__icon item__heart ${isFavourite ? "item__heart--filled" : ""}`}>
        <use href={sprites + "#icon-heart"}></use>
      </svg>
      {t("favourite")}      
    </div>
  );
};

export const ClubsConfirm = ({ setConfirmOpen, handleConfirm }) => {
  const { t } = useContext(LocaleContext);

  return (
    <CustomModal handleClose={() => setConfirmOpen(false)}>
      <div className="item-confirmation">
        <div className="item-confirmation__message heading-tertiary">{t("confirmRemoveFavourite")}</div>
        <button className="button--red" onClick={handleConfirm}>
          {t("yes")}
        </button>
        <button className="button--white" onClick={() => setConfirmOpen(false)}>
          {t("no")}
        </button>
      </div>
    </CustomModal>
  );
};

export const ItemDetails = ({ title = '', description = '', link, optionalNotes = [], hits = [], displayTags = [], score}) => {
  const { t, locale } = useContext(LocaleContext);

  const [fullDesc, toggleFullDesc] = useToggle(false);
  const descLimit = 160;

  // console.log("The displaytags are", displayTags)
  const tags = []
  for (let x of displayTags){

    let tag = {
      name: x.title[0].text,
    }

    for (let y of x.title){
      if (y.locale === locale){
        tag.name = y.text
      }
    }

    if (x.sortDimensionCampus){
      tag.sortDimensionCampus = x.sortDimensionCampus
    }

    tags.push(tag)
  }

  tags.sort((a, b) => {
    if (!b.sortDimensionCampus){
      return -1
    } else {
      return b.sortDimensionCampus - a.sortDimensionCampus
    }
  })

  useEffect(() => {
    if (fullDesc === true){
        console.log("hey")
        window.dataLayer.push({
            event: "resource_readmore",
            user_id: getUserIDfromCookies(),
            eventProps: {
                category: "resource_interest",
                action: "click",
                label: `Reading ${title}`,
                transport: "beacon"
            }
        })

        window.dataLayer.push({
            event: "select_item",
            user_id: getUserIDfromCookies(),
            item_list_name: "resource_readmore",
            items: [
                {
                    item_name: title,
                    item_category: window.location.pathname,
                    price: score || 0
                }
            ]
        })
    }
  }, [fullDesc])

  description =
    description.length > descLimit
      ? fullDesc
        ? description
        : `${description.substring(0, descLimit)}...`
      : description;

  return (
    <div className="item__details">
      <a href={link} target="_blank" rel="noopener noreferrer">
        <h2 className="item__title">{title}</h2>
      </a>
      <div className="item__description">
        { ReactHtmlParser(DOMPurify.sanitize(description)) }
        {description.length > descLimit && (
          <button className="item__read-more" onClick={toggleFullDesc}>
            {fullDesc ? t("readLess") : t("readMore")}
          </button>
        )}
      </div>
      
      <div className = "recommendation-hits-container">
          {hits.length > 0 && <div className = "hits-explanation">{t("hitsExplanation3")}</div>
          }
          {
              hits.map((e, index) => {
                  return (
                      <span className = "hit-instance" key = {index}> {`${e.name} (${e.score})`} </span>
                  )
              })
          }
          {
            tags.map((e, index) => {
              return (
                <span className = {`hit-instance hierarchy-score-${e.sortDimensionCampus}`} key = {index}> {`${e.name}`} </span>
              )
            })
          }
      </div>
      
      {optionalNotes.map((note, index) => {
        return (
          note && (
            <div className="item__note" key={index}>
              {note}
            </div>
          )
        );
      })}
    </div>
  );
};

export const ItemLink = ({ link, label, title='no title provided', score=0}) => {
  
  const handleLinkClick = (link) => {

      window.dataLayer.push({
        event: 'event',
        user_id: getUserIDfromCookies(),
        eventProps: {
          category: "resources",
          action: "click",
          label: `Resource redirects to: ${link}`
        }
      })

      window.dataLayer.push({
        event: "select_item",
        user_id: getUserIDfromCookies(),
        item_list_name: "resource_link",
        items: [
            {
                item_name: title,
                item_category: window.location.pathname,
                price: score || 0
            }
        ]
    })
    }
    
    return (
      <a className="item__option" href={link} target="_blank" rel="noopener noreferrer" onClick = {() => handleLinkClick(link)}>
        <svg className="item__icon">
          <use href={sprites + "#icon-web"}></use>
        </svg>
        <span>{label}</span>
      </a>
    )
  };

export const ItemCalendar = ({ calendarLink }) => {
  const { t } = useContext(LocaleContext);

  return (
    <a className="item__option" href={calendarLink} target="_blank" rel="noopener noreferrer">
      <svg className="item__icon">
        <use href={sprites + "#icon-calendar-blue"}></use>
      </svg>
      <span>{t("addToCalendar")}</span>
    </a>
  );
};

export const ItemSendToFriend = ({ content, title, dateTimeText }) => {
  const { t, locale } = useContext(LocaleContext);

  const handleClick = (content, title, dateTimeText, t, locale) => {

    window.dataLayer.push({
        event: "share",
        user_id: getUserIDfromCookies(),
        method: "clipboard",
        item_id: title
      })

    handleCopy(content, title, dateTimeText, t, locale)
  }

  return (
    <div className="item__option" onClick={() => handleClick(content, title, dateTimeText, t, locale)}>
      <svg className="item__icon">
        <use href={sprites + "#icon-copy"}></use>
      </svg>
      <span>{t("sendToFriend")}</span>
    </div>
  );
};

export const ItemFavourite = ({ isFavourite, handleFavourite, favsCount }) => {
  const { t } = useContext(LocaleContext);
  let label = isFavourite ? t("removeFavourite") : t("addFavourite");

  return (
    <div className="item__option" onClick={handleFavourite}>
        <svg className={`item__icon item__heart ${isFavourite ? "item__heart--filled" : ""}`}>
          <use href={sprites + "#icon-heart"}></use>
        </svg>
        <span>

          {favsCount > 1 &&
          `${favsCount} students like this.`}

          {favsCount === 1 &&
          `${favsCount} student likes this.`}

          {favsCount < 1 &&
          `Nobody has liked this yet.`}

        </span>
    </div>
  );
};

export const ItemRelevanceScore = ({ score }) => {
    const { t } = useContext(LocaleContext);
    // let label = isFavourite ? t("removeFavourite") : t("addFavourite");
  
    return (
      <div className="item__option">
        <span> {t("relevanceScore")}: {score} </span>
      </div>
    );
  };

export const ItemSocials = ({ instagram, facebook, twitter, discord }) => {
  return (
    <div className="socials">
        {!!instagram &&
          <a className="item__option" href={instagram} target="_blank" rel="noopener noreferrer">
            <h4>Instagram</h4>
          </a>
        }
        {!!facebook &&
          <a className="item__option" href={facebook} target="_blank" rel="noopener noreferrer">
            <h4>Facebook</h4>
          </a>
        }
        {!!twitter &&
          <a className="item__option" href={twitter} target="_blank" rel="noopener noreferrer">
            <h4>Twitter</h4>
          </a>
        }
        {!!discord &&
          <a className="item__option" href={discord} target="_blank" rel="noopener noreferrer">
            <h4>Discord</h4>
          </a>
        }
    </div>
  );
};

export const ItemConfirm = ({ setConfirmOpen, handleConfirm }) => {
  const { t } = useContext(LocaleContext);

  return (
    <CustomModal handleClose={() => setConfirmOpen(false)}>
      <div className="item-confirmation">
        <div className="item-confirmation__message heading-tertiary">{t("confirmRemoveFavourite")}</div>
        <button className="button--red" onClick={handleConfirm}>
          {t("yes")}
        </button>
        <button className="button--white" onClick={() => setConfirmOpen(false)}>
          {t("no")}
        </button>
      </div>
    </CustomModal>
  );
};