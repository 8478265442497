import React, { useCallback, useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom"
import GA4Pageview from "../../util/pageview"
import { Helmet } from "react-helmet";
import _ from 'lodash'

import LocaleContext from "contexts/locale";

import NavFlowList from "pages/_common/NavFlowList";
import SurveyRedirectButton from "./SurveyRedirectButton";

import { recommendationsApi, resourcesApi } from "api";

import cookies from "js-cookie"
import { withLayout } from "hoc/withLayout";


const Recommendations = () => {

  const location = useLocation()
  const params = new URLSearchParams(location.search)

  const pageTitle = GA4Pageview("Recommendations")

  const [loadingRecommendations, setLoadingRecommendations] = useState(true)
  const [recommendations, setRecommendations] = useState([]);
  const [tags, setTags] = useState([])
  const [appliedFilters, setAppliedFilters] = useState({});

  // This is starting from 1 because the pagination component starts its page at 1
  const [page, setPage] = useState(1);
  const [pages,setPages] = useState(0)
  
  const { t, locale } = useContext(LocaleContext);

  useEffect(() => {
    async function logRecommendation() {
        const navflowInstanceId = params.get("instance") || null
        if (navflowInstanceId && navflowInstanceId.substring(0, 2) !== "sa"){ // only run Mongodb navflow analytics if it is not Staff Account
            recommendationsApi.logrec(navflowInstanceId)
        }
    }

    logRecommendation()
  }, [])

  useEffect(() => {

        async function fetchData() {
        setLoadingRecommendations(true);

        try {
            // We are subtracting 1 from the page because the pagination component starts at index 1
            const result = await resourcesApi.getAllRecommendedResources({...appliedFilters, page: page - 1})

            if (result.data.length > 0){ // if there WERE recommendations, we're already viewing this page, so remove queued notification for it
                // To que notifications, we make sure they are not in the cookie string
                let bns = cookies.get("bns")
                if (!bns.includes("_vr_")) cookies.set("bns", bns + "_vr_")

                bns = cookies.get("bns")
                if (!bns.includes("_ts_")) cookies.set("bns", bns + "_ts_")
            }

            setRecommendations([...result.data]);
            setPages(result.total)

        } catch(e) {console.log(e)}

        setLoadingRecommendations(false);
        }
        fetchData();
  }, [appliedFilters, page, pages]);

  useEffect(() => {
    (async () => {
      try {
        const result = await resourcesApi.getMentalHealthResourceTags()
        setTags(result.data)
      } catch(e) {}
    })()
  }, [])

  const onPageChange = useCallback((page) => {
    setPage(page)
  }, [])

  const renderTitle = useCallback(() => {
    return (
        <div className = "recommendations__title-block">
            <div className="recommendations__retake">
                {/* <a href = {`https://intakeq.com/new/${t("intakeQID")}`} > Start </a> */}
                {/* <SurveyRedirectButton text={t("retakeSurvey")} locale={locale} formID={t("intakeQID")}/> */}
                <SurveyRedirectButton
                text={
                    recommendations > 0 ? t("retakeSurvey") : t("takeSurvey")
                }
                locale={locale}/>
            </div>
            <div className="recommendations__title"> {t("finishedSurveyTitle")} </div>
        </div>
    )
  }, [t])


  return (
    <>

      <Helmet>
        <meta charSet="utf-8" />
        <title> {pageTitle} </title>
      </Helmet>

      <NavFlowList
        appliedFilters={appliedFilters}
        onPageChange={onPageChange}
        tags={tags}
        loadingRecommendations={loadingRecommendations}
        total={pages}
        updateAppliedFilters={setAppliedFilters}
        currentPage={page}
        resources={recommendations}
        renderTitle={renderTitle}
        showScore
      />
    </>
  )
};

export default withLayout()(Recommendations);
