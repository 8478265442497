import { useContext, useEffect } from "react";

import LoadingPage from "pages/LoginSystem/LoadingPage";
import AuthContext from "contexts/auth";

const withAuthentication = () => Component => props => {
  const { logout, isAuthorized, isLoading, isLoginPage } = useContext(AuthContext);

  if (isLoading) {
    return (
      <LoadingPage />
    )
  } else if(!isAuthorized && !isLoginPage) {
    logout()
  } else {
    return <Component {...props}/>
  }

  return null;
}

export default withAuthentication;
