import { Link, CardHeader, CardBody, VStack, StackDivider, HStack, Card, Badge, Text, Tag } from "@chakra-ui/react"
import { Resource } from "api/resource";
import { Link as ReactRouterLink } from "react-router-dom";

type CardProps = {
    resource: Resource;
}

export const ResourceCard: React.FC<CardProps> = ({ resource }) => {
    return (
        // <Link as={ReactRouterLink as any} to={`/dashboard/resources/resource/${resource.id}`} w="full">
            <Card w="full" shadow='lg'>
                <CardHeader>
                    <Text fontWeight={800} fontSize={24} color='gray.600'>{resource.name}</Text>
                    <Text fontWeight={500} fontSize={18} size="xs" color="gray.600">{resource.description}</Text>
                </CardHeader>
                <CardBody>
                    <VStack divider={<StackDivider />} alignItems="start">
                        {
                            !!resource?.groups?.length && (
                                <>
                                    <HStack alignItems="start">
                                        {resource?.groups?.map((group) => (
                                            <Tag
                                                fontSize={16} 
                                                variant='outline'
                                                colorScheme='cyan' 
                                                p={4}
                                                borderRadius={50}
                                                color='rgba(125, 201, 237, 1)' 
                                                fontWeight={800}
                                            >
                                                {group.name}
                                            </Tag>
                                        ))}
                                    </HStack>
                                </>
                            )
                        }
                    </VStack>
                </CardBody>
            </Card>
        // </Link>
    )
}

export const EmptyResourceCard = () => (
    <Card w="full" shadow='lg'>
        <CardHeader>
        </CardHeader>
        <CardBody>
            <VStack divider={<StackDivider />} alignItems="start">
                <Text>
                    Select a filter group to see resources
                </Text>
            </VStack>
        </CardBody>
    </Card>
)