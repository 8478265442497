import { Route, Switch, Redirect } from "react-router-dom";
// Util
import ScrollToTop from "util/scrollToTop";

import Alert from "components/Alert/Alert";

// Login
import Login from "pages/LoginSystem/Login";

// Contexts
import { LocaleContextProvider } from "contexts/locale";

// Routes
import AuthenticatedRoutes from "routes/AuthenticatedRoutes";
import useWindowSize from "./util/useWindowSize"

// Style for Ant Design
// Ideally we could find a better solution for this as AntDesign inserts
// its theme into the CSS body element so effects everything
import "antd/dist/antd.min.css";


const App = () => {


  const { width, height } = useWindowSize();
    
  return (
    <>
      <canvas width={width} height={height} id = "confetti"></canvas>
      <LocaleContextProvider>
        {/* Sets up Alert globally so it can be seen on any page */}
        <Alert />
        {/* When users jump to another page, it will scroll to the top */}
        <ScrollToTop />

        <Switch>
          {/* Login system */}
          <Route exact path={"/"} component={() => <Redirect to="/login" />} />
          <Route exact path={"/login"} component={Login} />
          <AuthenticatedRoutes />
        </Switch>
      </LocaleContextProvider>
    </>
  );
};

export default App;
