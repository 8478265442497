import React, { useContext, useEffect, useState } from "react";
import * as Yup from 'yup';
import { useFormik } from 'formik';

import cpLogo from "imgs/navbar/ww-logo.png";

import LocaleContext from "contexts/locale";

import { studentApi } from "api";

import "./Profile.scss";

import { YEARS_OF_STUDY } from "./constants";


const Profile = ({org, user, closeProfile}) => {

  let showPreferredName = true
  if (org && org.snowflake && org.snowflake &&  org.snowflake.showPreferredName === false){
    showPreferredName = false
  }

  const { t, locale } = useContext(LocaleContext);

  const validationSchema = Yup.object().shape({
    name: Yup.string(),
    yearOfStudy: Yup.string()
  });
  const initialValues = { yearOfStudy: '', name: '' };
  const onSubmit = async (values, { setSubmitting }) => {
    try {
      await studentApi.updateProfile(values);
      closeProfile()
    } catch(e) {}

    setSubmitting(false)
  };

  const formik = useFormik({ initialValues, validationSchema, onSubmit });
  const { handleSubmit, handleChange, values, errors, isSubmitting, setValues } = formik;

  useEffect(() => {
    const getProfile = async () => {
      try {
        for (const names of org.name){
            if (names.locale === locale) user.university = names.text
        }

        const result = await studentApi.getProfile()
        setValues(result.data)

      } catch(e) {
        console.log(e)
      }
    }

    getProfile()
  }, [org, user, locale, setValues])

  return (
    <div className="profile">
      <img className="profile__logo" src={cpLogo} alt={"Wellness World Logo"} />

      <div className="profile__inputs">
        <div>{t("university")}</div>
        <span>{values.university}</span>

        <div>{ showPreferredName === false ? "id" : t("email") }</div>
        <span>{ values.email }</span>

        {showPreferredName !== false && (
          <>
            <div>{t("preferredName")}</div>
            <input value={values.name} onChange={handleChange('name')} />
          </>
        )}

        <div>{t("yearOfStudy")}</div>
        <select value={values.yearOfStudy} onChange={handleChange('yearOfStudy')}>
          <option value={YEARS_OF_STUDY.FIRST}>{t("firstYear")}</option>
          <option value={YEARS_OF_STUDY.SECOND}>{t("secondYear")}</option>
          <option value={YEARS_OF_STUDY.THIRD}>{t("thirdYear")}</option>
          <option value={YEARS_OF_STUDY.FOURTH}>{t("fourthYear")}</option>
          <option value={YEARS_OF_STUDY.FIFTH}>{t("fifthYear")}</option>
        </select>
      </div>

      {isSubmitting ? (
        <button className="profile__button profile__button--saving">{t("saving")}</button>
      ) : (
        <button className="profile__button" onClick={handleSubmit}>
          {t("save")}
        </button>
      )}
    </div>
  );
};

export default Profile;
