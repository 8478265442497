import { sendAlert } from "redux/_actions/data";
import dedent from "dedent";

export const handleCopy = (item, heading, dateString = "", t, locale = 'en-CA') => {
  let {
    link,
  } = item;
  // IMPORTANT: DO NOT CHANGE HOW THIS FILE IS INDENTED AS IT WILL AFFECT HOW IT
  // APPEARS ON THE SEND TO FRIEND TEXT THAT IS COPIED TO CLIPBOARD
  let finalText = dedent(`${heading}
                            ${item.getLocaleTitle(locale)}
                            ${item.getLocaleDetail(locale)}
  
                            ${
                              link
                                ? `${t("checkOutTheLink")}: ${link}`
                                : ""
                            }
                            ${dateString}`);
  copyDetailsToClipboard(finalText, t);
};

const copyDetailsToClipboard = (text, t) => {
  const el = document.createElement("textarea");
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  sendAlert(t("copyToClipboard"));
};
