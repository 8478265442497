import { Button, Grid, GridItem, HStack, Spinner, Tag, Text, VStack } from "@chakra-ui/react";
import { filterGroupV2Api } from "api/filter-group";
import { FilterGroup, Group, groupV2Api } from "api/group";
import { useIntent } from "hooks/useIntent";
import { useEffect, useMemo, useState } from "react";

type SpecificFilterGroupPickerProps = {
    parentId: string;
    parentName?: string;
    useIntent: Pick<ReturnType<typeof useIntent>, 'selectedFilterGroups' | 'setSelectedFilterGroups'>;
}

const SpecificFilterGroupPicker: React.FC<SpecificFilterGroupPickerProps> = ({ parentId, useIntent, parentName }) => {
    const [filterGroups, setFilterGroups] = useState<FilterGroup[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { selectedFilterGroups, setSelectedFilterGroups } = useIntent;

    const init = async () => {
        setIsLoading(true);
        const filterGroups = await filterGroupV2Api.getSearchableByParentGroup(parentId);
        setFilterGroups(filterGroups);
        setIsLoading(false);
    }

    useEffect(() => {
        init();
    }, [])

    const batchedFilterGroups = useMemo(() => {
        const batchedFilterGroups: FilterGroup[][] = [];
        const COL_LENGTH = 3;
    
        for (let i = 0; i < filterGroups.length; i += COL_LENGTH) {
            batchedFilterGroups.push(filterGroups.slice(i, i + COL_LENGTH))
            }
        
        return batchedFilterGroups;
    }, [filterGroups])

    const onClick = (filterGroup: FilterGroup, isSelected: boolean) => {
        if (isSelected) {
            setSelectedFilterGroups(selectedFilterGroups.filter(selectedFilterGroup => selectedFilterGroup.id !== filterGroup.id));
        } else {
            setSelectedFilterGroups([...selectedFilterGroups, filterGroup]);
        }
    }
    
    const renderFilterGroup = (filterGroup: FilterGroup) => {
        const isSelected = selectedFilterGroups?.some(selectedFilterGroup => selectedFilterGroup.id === filterGroup.id);

        return (
            <Tag
                onClick={() => onClick(filterGroup, isSelected)}
                size='lg'
                fontSize={16}
                fontWeight={800}
                px={4}
                py={3}
                borderRadius='2xl'
                backgroundColor={isSelected ? 'gray.100' : 'white'}
                boxShadow='0px 2px 16px -3px rgba(45, 55, 72, 0.03)'
                cursor='pointer'
            >
                {filterGroup.group.name}
            </Tag>
        )
    }

    const renderFilterGroupRow = (filterGroupRow: FilterGroup[]) => {
        return (
            <HStack spacing={1}>
                {filterGroupRow.map(renderFilterGroup)}
            </HStack>
        )
    }

    return (
        <VStack>
            {parentName && <Text>{parentName}</Text>}
            <VStack alignItems='start'>
                {isLoading ? ( <Spinner /> ) : ( batchedFilterGroups.map(renderFilterGroupRow) )}
            </VStack>
        </VStack>
    )
}

type FilterGroupPickerProps = {
    showParentNames?: boolean;
    useIntent: Pick<ReturnType<typeof useIntent>, 'selectedFilterGroups' | 'setSelectedFilterGroups' | 'isLoading'>
}

export const FilterGroupPicker: React.FC<FilterGroupPickerProps> = ({ showParentNames, useIntent }) => {
    const [parentGroups, setParentGroups] = useState<Group[]>();
    const [isLoadingParentGroups, setIsLoadingParentGroups] = useState<boolean>(false);

    const intent = useIntent;

    const init = async () => {
        setIsLoadingParentGroups(true);
        const groups = await groupV2Api.getParents();
        setParentGroups(groups);
        setIsLoadingParentGroups(false);
    }
    console.log(parentGroups, isLoadingParentGroups)
    useEffect(() => { init(); }, [])

    return (
        <VStack spacing={12} alignItems='start'>
            {isLoadingParentGroups ? (
                <Spinner />
            ): (
                parentGroups?.map(group => (
                    <SpecificFilterGroupPicker
                        parentId={group.id} 
                        parentName={showParentNames ? group.name : undefined} 
                        useIntent={intent}
                    />
                ))
            )}
        </VStack>
    )
}